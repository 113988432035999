import Vue from 'vue'
import Vuex from 'vuex'
import {getHotelConfig} from "@/utils/config";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        hotelInfo: getHotelConfig(),
        loginStatus: !!localStorage.getItem('token'),
        loginType: localStorage.getItem('loginType') || 1,
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
})
