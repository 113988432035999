import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'
import './assets/icon/iconfont.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './theme/index.css'
import VueI18n from 'vue-i18n'

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$api = api;

function getUrlKey(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [undefined, ""])[1].replace(/\+/g, '%20')) || null;
}
if (getUrlKey('lang')) {
    localStorage.setItem('lang', getUrlKey('lang'))
} else {
    if (!localStorage.getItem('lang')) {
        localStorage.setItem('lang', 'cn')
    }
}

Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'cn',
    messages: {
        'cn': require('./lang/cn'),
        'en': require('./lang/en')
    },
    silentTranslationWarn: true, // 去除国际化警告
})

Vue.prototype.$bus = new Vue()
Vue.prototype.$scrollTo = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant' // 滚动行为：smooth平滑滚动，instant瞬间滚动，默认值auto，等同于instant
    })
}

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
