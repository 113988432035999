import axios from '@/utils/request';

const protocol = {
    getProtocolRoomList(params) {
        return axios.post('/v1/protocol.room.list.post', params);
    },
    login(params) {
        return axios.post('/v1/protocol.login.post', params);
    },
    logout(params) {
        return axios.post('/v1/protocol.logout.post', params);
    },
    roomOrderList(params) {
        return axios.get('/v1/protocol.orderList.get', {params: params});
    },
    roomOrderDetail(params) {
        return axios.get('/v1/protocol.orderDetail.get', {params: params});
    },
    //自有系统协议单位客房下单
    protocolAddOrder(params) {
        return axios.post('/v1/protocol.room.addOrder.post', params);
    },
    //pms协议单位客房下单
    pmsProtocolAddOrder(params) {
        return axios.post('/v1/room.addCompanyOrder.post', params);
    },
    //自有系统协议单位取消订单
    roomOrderCancel(params) {
        return axios.post('/v1/protocol.room.order.cancel', params);
    },
    //积分记录
    userPoints(params) {
        return axios.get('/v1/protocol.pcPointLog.get', {params: params});
    },
    //积分记录
    userInfo(params) {
        return axios.get('/v1/protocol.info.get', {params: params});
    },
    //信息修改
    editInfo(params) {
        return axios.post('/v1/protocol.infoEdit.post ', params);
    },//修改密码
    editPassword(params) {
        return axios.post('/v1/protocol.passwordEdit.post', params);
    },
    invoiceList(params) {
        return axios.get('/v1/protocol.getInvoiceList.get', params);
    },
    addInvoice(params) {
        return axios.post('/v1/protocol.addMemberInvoice.post', params);
    },


}

export default protocol;