module.exports = {
    language: {
        name: '简体中文'
    },
    user: {
        loginTxt: 'Login',
        registerTxt: 'Register',
        registerNow: 'Register Now',
        loginSuccess: 'Login Success',
        registerSuccess: 'Register success',
        promise: 'Official channel reservation enjoys low price guarantee.',
        phone: 'Please enter your mobile phone number',
        validPhone: 'Please enter a valid phone number',
        code: 'Please enter confirmation code',
        sending: 'Sending',
        validCode: 'Input verification code format error',
        emailTxt: 'Please enter an email address',
        validEmail: 'Please enter a valid email address',
        loginPassword: 'Please enter your login password',
        notLogin: 'Do not log in ，Skip',
        morningTip: 'Good morning！',
        noonTip: 'Good afternoon！',
        nightTip: 'Good evening！',
        welcomeRegister: 'Welcome to register',
        openingHotelJourney: 'Opening Our Hotel Journey',
        userCenter: 'User Center',
        officialMember: 'Member',
        protocolCompany: 'Corporate account',
        orderInquiry: 'Order Inquiry',
        firstName: 'Firstname',
        firstNameTxt: 'Please enter your first name',
        lastName: 'Lastname',
        lastNameTxt: 'Please enter last name',
        gender: 'Gender',
        male: 'male',
        female: 'female',
        contactInformation: 'Contact Information',
        mobile: 'Mobile',
        tel: 'Tel',
        telTxt: 'Please enter a tel number',
        email: 'Email',
        birthday: 'Birthday',
        selectBirthday: 'Select birthday',
        roomRequires: 'Room requires',
        roomRequiresTxt: 'Please enter the room requires',
        orderConfirmEmail: '这是我们将向您发送订单确认信息的电子邮件地址',
        expectedArrival: 'Expected arrival',
        specialRequirements: 'Special requirements',
        arrivedTime: 'Arrived time',
        selectTime: 'Select time',
        remarks: 'Remarks',
        remarksTxt: 'Please enter remarks',
        guestName: 'Guest name',
        invoiceTitle: 'invoice Title',
        passwordTxt: 'Please enter the password',
        loginNameTxt: 'Please enter the login account',
        invoiceContactNameTxt: 'Please enter a contact person',
        sendAddressTxt: 'Please enter the delivery address',
        editInfoSuccTxt: 'User profile updated successfully',
        userInformation: 'User information',
        oldPass: 'Original password',
        oldPassTxt: 'Please enter the original password',
        newPass: 'New password',
        newPassTxt: 'Please enter a new password',
        validPassTxt: 'Please enter a valid password',
        matchPassTxt: 'The two passwords entered do not match',
        confirmPass: 'Confirm password',
        confirmPassTxt: 'Please enter the new password again',
        inputPassTxt: 'The password is 8-18 digits, including numbers, uppercase and lowercase letters, and special characters',
        respected: 'Respected',
        member: 'Member',
        membershipCard: 'membership card',
        joinMember: 'Join the official website as a member',
        joinMemberTxt: 'Enjoy exclusive membership benefits, exclusive membership prices and exciting benefits',
        upgradingPoints: 'Points required for upgrading',
        currentLevel: 'Benefits available at current level',
        moreLevel: 'More level benefits',
        moreBenefits: 'More benefits',
        memberBenefits: 'Member benefits',
        securityInfo: 'Please improve your personal information in order to receive better service, Safely keep your login password to ensure the security of your account',
        rule: 'Use rules',
        exchangeGift: 'Exchange gift',
        pointsBalance: 'Points balance',
        divide: 'divide',
        myPoints: 'My Points',
        dateQuery: 'Date Query',
        search: 'Search',
        pointsChange: 'Points change',
        description: 'Description',
        createdTime: 'Created time',
        ruleDesc: 'Use rule',
        useHotel: 'Applicable hotel',
        couponName: 'Coupon name',
        useScene: 'Applicable scene',
        useRule: 'Use rule',
        startEndTime: 'Expiry date',
        LongTermEffective: 'Long-term effective',
    },
    search: {
        checkIn: 'Check-in',
        checkOut: 'Check-out',
        evening: 'Night',
        citySelect: 'Choose city',
        hotelSelect: 'Choose hotel',
        roomGuest: 'Rooms and Guest',
        roomCount: 'Room',
        roomNumber: 'Room',
        peoples: 'Person',
        kids: 'Kids',
        confirm: 'Confirm',
        reserveCode: 'Promotion number',
        inputReserveCode: 'Promotion number',
        protocolCode: 'Protocol number',
        inputProtocolCode: 'Protocol number',
        resetSearch: 'Search',
        specialRoomPrice: 'Special code or housing price',
        to: 'To',
        filterItems: 'Filter items',
        breakfast: 'Breakfast',
        bedType: 'Bed type',
        window: 'Windows',
    },
    home: {
        userCenter: 'User center',
        personalCenter: 'Personal Center',
        memberHome: 'Member home',
        booking: 'Booking',
        points: 'My points',
        pointsBalance: 'Points balance',
        cardVoucher: 'Card voucher',
        couponVoucher: 'Coupon voucher',
        giftVoucher: 'Gift voucher',
        freeVoucher: 'Free voucher',
        myOrder: 'My orders',
        roomOrders: 'Room orders',
        mallOrders: 'Mall Orders',
        electronicOrders: 'Electronic Orders',
        pointsMallOrders: 'Points Mall Orders',
        snapOrders: 'Snap orders',
        foodOrders: 'Food orders',
        meetingOrders: 'Meeting orders',
        activityOrders: 'Activity orders',
        apartmentOrders: 'Apartment orders',
        equityCardOrders: 'Equity card orders',
        commonInformation: 'Common information',
        shippingAddress: 'Shipping address',
        invoiceInformation: 'Invoice information',
        accountSettings: 'Account settings',
        basicData: 'Profile',
        changePassword: 'Change password',
        logout: 'Logout',
        loggedOut: 'Logged out',
        noData: 'No data',
    },
    room: {
        address: 'Address',
        telephone: 'Telephone',
        openTime: 'Opening time',
        roomNumber: 'Rooms number',
        moreFacilities: 'Show more hotel facilities',
        close: 'Close',
        minPrice: 'Min housing price',
        start: 'Start',
        bookingNow: 'Booking now',
        nowNoRoom: 'No room',
        roomDetails: 'Room details',
        roomPriceDetails: 'Room price details',
        room: 'Room',
        hall: 'Hall',
        people: 'People',
        floor: 'Floor',
        cancelable: 'cancelable',
        nonCancelable: 'Non cancelable',
        onlinePayment: 'Prepa',
        cashPayment: 'Pay at hotel',
        purePoints: 'Pure points',
        points: 'points',
        cashPoints: 'cash + points',
        noData: 'No room information found',
        fillBookingInfo: 'Booking information',
        paymentMethod: 'Payment',
        roomConfirm: 'Rooms confirmation',
        bookingInformation: 'Booking information',
        bookingRoomNumber: 'Rooms',
        guest: 'Guest',
        occupancy: 'Number of Person',
        bookingDate: 'Checkin',
        checkInDays: 'Nights',
        chooseAddedServices: 'Other services',
        addedServicesName: 'Production',
        addedServicesContent: 'Describe',
        addedServicesPrice: 'Unit Price',
        addedServicesQuantity: 'Choose',
        free: 'Free',
        guestInformation: 'Guest information',
        checkInInformation: 'Check-In information',
        total: 'Total',
        back: 'Prev',
        next: 'Next',
        expenseDetails: 'Expense details',
        roomRate: 'Room rate information',
        date: 'Date',
        bf: 'BF',
        night: 'Night',
        room_number: 'Room',
        amount: 'Amount',
        subtotal: 'Subtotal',
        serviceFees: 'Service charge',
        otherServices: 'Other services',
        discountDetails: 'Discount details',
        discountName: 'Name',
        discountAmount: 'Amount',
        priceDescription: 'Price Description',
        reservetionPolicy: 'Reservetion policy',
        cancelltionPolicy: 'Cancelltion policy',
        couponEmptyData: 'No data',
        guestCheckInInformation: 'Guest check-in information',
        bookingConditions: 'I am 18 years of age, I abide by the terms and conditions of this site and have been reading on the right booking conditions',
        confirmSubmit: 'Submit',
        submitSuccessPay: 'Order Successfully Submitted',
        checkBookingNotice: 'Please check the reservation terms notice',
        submitSuccess: 'Order successfully submitted',
        waitingPayTime: 'waiting for payment time',
        orderNo: 'Order no',
        orderNoTxt: 'Please enter the order number',
        wechatScan: 'Please use WeChat to scan',
        scanQRPay: 'Scan QR code payment',
        effectiveEmail: 'Please ensure proper effective email',
        couponPackSelect: 'Coupon Pack Selection',
        pleaseSelect: 'Please select',
        couponVoucher: 'Coupon voucher',
        giftVoucher: 'Gift voucher',
        checkInTime: 'Check-in',
        createTime: 'Create time',
        orderDetails: 'Order details',
        editOrder: 'Modify Order',
        payNow: 'Pay now',
        cancelOrder: 'Cancel order',
        totalPage: 'total',
        page: 'page',
        records: 'records',
        searchKeyword: 'first name、phone number、order number',
        searchDate: 'Date query',
        startDate: 'Start date',
        endDate: 'End date',
        query: 'Search',
        cancelOrderSuccess: 'Order cancelled successfully',
        orderStatus: 'Order status',
        toPay: 'To pay',
        surplus: 'Surplus',
        nowPaymentTxt: 'order successfully submitted. Please now payment',
        invoiceTitle: 'Invoice title',
        invoiceType: 'Invoice type',
        wayGetInvoice: 'Collection method',
        getDate: 'Collection date',
        getDateTxt: 'Please choose the collection date',
        getTime: 'Collection time',
        getTimeTxt: 'Please choose the collection time',
        receviePerson: 'Recipient',
        receviePersonTxt: 'Please enter recipient',
        deliverAddr: 'Address',
        deliverAddrTxt: 'Please enter the address',
        addInvoiceInformation: 'Add invoice information',
        ordinaryInvoice: 'VAT ordinary invoice',
        specialInvoice: 'VAT special invoice',
        personal: 'Personal',
        company: 'Company',
        companyName: 'Company name',
        companyNameTxt: 'Please enter company name',
        taxNum: 'Company tax number',
        taxNumTxt: 'Please enter company tax number',
        registerAddr: 'Registered address',
        registerAddrTxt: 'Please enter company registered address',
        registerPhone: 'Registered phone',
        registerPhoneTxt: 'Please enter company registered phone',
        bankName: 'Bank of deposit',
        bankNameTxt: 'Please enter bank name',
        bankCount: 'Bank Account',
        bankCountTxt: 'Please enter bank account',
        connectPhone: 'Contact mobile',
        connectPhoneTxt: 'Please enter contact mobile',
        name: 'Name',
        nameTxt: 'Please enter your name',
        save: 'Save',
        hotelDescription: 'Hotel description',
        paymentInstructions: 'Payment instructions',
        paymentInstructionsTxt: 'Please complete the payment for the pre order within 30 minutes. If it exceeds the deadline, the order will be automatically cancelled',
        exceedsTxt: 'If it exceeds the deadline',
        exceedsCancelTxt: ', the order will be automatically cancelled',
        pendingExceedsCancelTxt: 'Orders that have not exceeded the payment deadline will be re paid in the [Personal Center Pending Payment] ',
        success: 'Payment successful',
        estimatedRoomRate: 'Estimated room rate',
        week: 'Week',
        basicInformation: 'Basic information',
        roomTypeFacilities: 'Room type facilities',
        roomPriceLow: 'Room price low',
    }

}