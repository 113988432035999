<template>
  <div id="app">
    <router-view/>
    <com-footer></com-footer>
  </div>
</template>

<script>
import comFooter from '@/components/footer/footer.vue'

export default {
  components: {
    comFooter
  },
  data() {
    return {
      hotelHost: window.location.host,
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  font-size: 14px;
}

body {
  margin: 0;
  padding: 0;
}
</style>
