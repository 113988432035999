<template>
  <component :is="cpFooter"></component>
</template>
<script>
export default {
  name: 'comFooter',
  data() {
    return {
      cpFooter: '',
    }
  },
  created() {
    let url = window.location.host;
    let index = url.indexOf('-');
    let value = url.slice(0, index);
    let footer = index > -1 ? value : 'szjingdu';
    this.cpFooter = () => import('@/components/footer/' + footer + '.vue');
  }
}
</script>