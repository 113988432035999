import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        meta: {
            requireAuth: false,
        },
        component: () => import('@/views/index.vue'),
    },
    {
        path: '/success',
        name: 'success',
        meta: {
            requireAuth: false,
        },
        component: () => import('@/views/success.vue'),
    },
    {
        path: '/room/hotel-list',
        name: 'hotelList',
        meta: {
            requireAuth: false,
        },
        component: () => import('@/views/room/hotel-list.vue'),
    },
    {
        path: '/room/price-list',
        name: 'priceList',
        meta: {
            requireAuth: false,
        },
        component: () => import('@/views/room/price-list.vue'),
    },
    {
        path: '/room/booking-detail',
        name: 'bookingDetail',
        meta: {
            requireAuth: false,
        },
        component: () => import('@/views/room/booking-detail.vue'),
    },
    {
        path: '/room/payment-method',
        name: 'paymentMethod',
        meta: {
            requireAuth: false,
        },
        component: () => import('@/views/room/payment-method'),
    },
    {
        path: '/pay-success',
        name: 'paySuccess',
        meta: {
            requireAuth: false,
        },
        component: () => import('@/views/pay-success.vue'),
    },
    {
        path: '/ticket-list',
        name: 'ticketList',
        meta: {
            requireAuth: false,
        },
        component: () => import('@/views/ticket-list.vue'),
    },
    {
        path: '/shop-list',
        name: 'shopList',
        meta: {
            requireAuth: false,
        },
        component: () => import('@/views/shop-list.vue'),
    },
    {
        path: '/seat-list',
        name: 'seatList',
        meta: {
            requireAuth: false,
        },
        component: () => import('@/views/seat-list.vue'),
    },
    {
        path: '/meeting-list',
        name: 'meetingList',
        meta: {
            requireAuth: false,
        },
        component: () => import('@/views/meeting-list.vue'),
    },
    {
        path: '/food-list',
        name: 'foodList',
        meta: {
            requireAuth: false,
        },
        component: () => import('@/views/food-list.vue'),
    },
    {
        path: '/register',
        name: 'register',
        meta: {
            requireAuth: false,
        },
        component: () => import('@/views/register.vue'),
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            requireAuth: false,
        },
        component: () => import('@/views/login.vue'),
    },
    {
        path: '/user/center',
        name: 'userCenter',
        meta: {
            requireAuth: true,
        },
        component: () => import('@/views/user/center.vue'),
    },
    {
        path: '/user/room-order-list',
        name: 'userRoomOrderList',
        meta: {
            requireAuth: true,
        },
        component: () => import('@/views/user/room-order-list.vue'),
    },
    {
        path: '/user/room-order-detail',
        name: 'userRoomOrderDetail',
        meta: {
            requireAuth: true,
        },
        component: () => import('@/views/user/room-order-detail.vue'),
    },
    {
        path: '/user/points-list',
        name: 'userPointsList',
        meta: {
            requireAuth: true,
        },
        component: () => import('@/views/user/points-list.vue'),
    },
    {
        path: '/user/coupon-list',
        name: 'userCouponList',
        meta: {
            requireAuth: true,
        },
        component: () => import('@/views/user/coupon-list.vue'),
    },
    {
        path: '/user/gift-list',
        name: 'userGiftList',
        meta: {
            requireAuth: true,
        },
        component: () => import('@/views/user/gift-list.vue'),
    },
    {
        path: '/user/free-list',
        name: 'userFreeList',
        meta: {
            requireAuth: true,
        },
        component: () => import('@/views/user/free-list.vue'),
    },
    {
        path: '/user/user-info',
        name: 'userInfo',
        meta: {
            requireAuth: true,
        },
        component: () => import('@/views/user/user-info.vue'),
    },
    {
        path: '/user/chang-password',
        name: 'userChangPassword',
        meta: {
            requireAuth: true,
        },
        component: () => import('@/views/user/chang-password.vue'),
    },
    {
        path: '/room/search-order-detail',
        name: 'searchOrderDetail',
        meta: {
            requireAuth: false,
        },
        component: () => import('@/views/room/search-order-detail.vue'),
    },
    {
        path: '/user/invoice-list',
        name: 'invoiceList',
        meta: {
            requireAuth: true,
        },
        component: () => import('@/views/user/invoice-list.vue'),
    },
    //协议公司
    {
        path: '/protocol/room-order-list',
        name: 'protocolRoomOrderList',
        meta: {
            requireAuth: true,
        },
        component: () => import('@/views/protocol/room-order-list.vue'),
    },
    {
        path: '/protocol/room-order-detail',
        name: 'protocolRoomOrderDetail',
        meta: {
            requireAuth: true,
        },
        component: () => import('@/views/protocol/room-order-detail.vue'),
    },
    {
        path: '/protocol/points-list',
        name: 'protocolPointsList',
        meta: {
            requireAuth: true,
        },
        component: () => import('@/views/protocol/points-list.vue'),
    },
    {
        path: '/protocol/chang-password',
        name: 'protocolChangPassword',
        meta: {
            requireAuth: true,
        },
        component: () => import('@/views/protocol/chang-password.vue'),
    },
    {
        path: '/protocol/user-info',
        name: 'protocolUserInfo',
        meta: {
            requireAuth: true,
        },
        component: () => import('@/views/protocol/user-info.vue'),
    },
    {
        path: '/protocol/invoice-list',
        name: 'protocolInvoiceList',
        meta: {
            requireAuth: true,
        },
        component: () => import('@/views/protocol/invoice-list.vue'),
    },
    {
        path: '/submit-token',
        name: 'submitToken',
        meta: {
            requireAuth: true,
        },
        component: () => import('@/views/submit-token.vue'),
    },
]

const router = new VueRouter({
    routes
})

// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

// 为路由对象添加路由导航守卫
router.beforeEach((to, from, next) => {
    // 动态配置favicon
    let favicon = window.location.origin + '/icon/' + store.state.hotelInfo.hotel_id + '.ico'
    document.querySelector('link[rel="icon"]').setAttribute('href', favicon)
    document.title = localStorage.getItem('lang') === 'en' ? 'Hotel Room Booking' : '酒店客房预订';

    // 检测是否登录
    if (to.meta.requireAuth) {
        let token = localStorage.getItem('token')
        if (token) {
            next()
        } else {
            if (store.state.hotelInfo.hotel_type === 2) {
                next({path: '/room/hotel-list'})
            } else {
                next({path: '/room/price-list'})
            }
        }
    } else {
        next()
    }
})

export default router
