import axios from '@/utils/request'; // 导入http中创建的axios实例

const hotel = {
    getHotelList(params) {
        return axios.get('/v1/hotel.hotelList.get', { params: params });
    },
    getProtocolHotelList(params) {
        return axios.get('/v1/protocol.hotelList.get', { params: params });
    },
    getHotelInfo(params) {
        return axios.get('/v1/info.hotel.get', { params: params });
    },
}

export default hotel;