import hotel from '@/api/hotel';
import room from '@/api/room';
import user from '@/api/user';
import protocol from "@/api/protocol";

// 导出接口
export default {
    hotel,
    room,
    protocol,
    user,
}