import axios from '@/utils/request';

const user = {
    login(params) {
        return axios.post('/v1/member.login.post', params);
    },
    protocolLogin(params) {
        return axios.post('/v1/protocol.login.post', params);
    },
    logout(params) {
        return axios.post('/v1/member.logout.post', params);
    },
    register(params) {
        return axios.post('/v1/mini_program.login.post', params);
    },
    verifyCode(params) {
        return axios.post('/v1/sms.verifyCode.post', params);
    },
    emailVerifyCode(params) {
        return axios.post('/v1/email.verify.post', params);
    },
    couponList(params) {
        return axios.get('/v1/coupon.list.get', {params: params});
    },
    giftList(params) {
        return axios.post('/v1/gift.list.post', params);
    },
    roomOrderList(params) {
        return axios.get('/v1/member.orderList.get', {params: params});
    },
    roomOrderDetail(params) {
        return axios.get('/v1/member.orderDetail.get', {params: params});
    },
    //会员取消订单
    roomOrderCancel(params) {
        return axios.post('/v1/room.order.cancel', params);
    },
    userPoints(params) {
        return axios.get('/v1/member.pcPointLog.get', {params: params});
    },
    userCoupon(params) {
        return axios.get('/v1/coupon.pcList.get', {params: params});
    },
    userGift(params) {
        return axios.get('/v1/gift.pcList.get', {params: params});
    },
    userFree(params) {
        return axios.get('/v1/free_coupon.pcList.get', {params: params});
    },
    userInfo(params) {
        return axios.get('/v1/member.info.get', {params: params});
    },
    userLevel(params) {
        return axios.get('/v1/member_level.list.get', {params: params});
    },
    editInfo(params) {
        return axios.post('/v1/member.infoEdit.post', params);
    },
    editPassword(params) {
        return axios.post('/v1/member.passwordEdit.post', params);
    },
    invoiceList(params) {
        return axios.get('/v1/member.getInvoiceList.get', params);
    },
    addInvoice(params) {
        return axios.post('/v1/member.addMemberInvoice.post', params);
    },
    memberLevelEquity(params) {
        return axios.get('/v1/member_level.equity_list.get', {params: params});
    },

}

export default user;