/**
 * 前端酒店上线前需要配置的数据
 * @author simon.dong
 * @returns {{}}
 */
export const getHotelConfig = () => {
    let obj = {};
    switch (window.location.host) {
        case 'junahotels-pcbooking.eworldsoft.com':
            obj.hotel_id = 1171;
            obj.hotel_type = 2;//2集团
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://junahotels.eworldsoft.com/api';
            obj.web_url = 'https://www.junahotels.com';
            obj.hotel_logo = 'https://www.junahotels.com/images/logo-1.png';
            obj.menu = [
                {
                    'name': '首页',
                    'en_name': 'Home',
                    'cn_url': 'https://www.junahotels.com/cn/index.html',
                    'en_url': 'https://www.junahotels.com/en/index.html',
                },
                {
                    'name': '君来消息',
                    'en_name': 'News',
                    'cn_url': 'https://www.junahotels.com/cn/news.html',
                    'en_url': 'https://www.junahotels.com/en/news.html',
                },
                {
                    'name': '旗下企业',
                    'en_name': 'Our Brands',
                    'cn_url': 'https://www.junahotels.com/cn/index.html#total_brand',
                    'en_url': 'https://www.junahotels.com/en/index.html#total_brand',
                },
                {
                    'name': '优惠精选',
                    'en_name': 'Special Offers',
                    'cn_url': 'https://www.junahotels.com/cn/group-buying.html',
                    'en_url': 'https://www.junahotels.com/en/group-buying.html',
                },
                {
                    'name': '餐饮美食',
                    'en_name': 'Dining',
                    'cn_url': 'https://www.junahotels.com/cn/index.html#total_food',
                    'en_url': 'https://www.junahotels.com/en/index.html#total_food',
                },
                {
                    'name': '婚宴及会议',
                    'en_name': 'Weddings &amp; Meetings',
                    'cn_url': 'https://www.junahotels.com/cn/index.html#total_meet',
                    'en_url': 'https://www.junahotels.com/en/index.html#total_meet',
                },
                {
                    'name': '会员礼遇',
                    'en_name': 'VIP Privilege',
                    'cn_url': 'https://junahotels-pcbooking.eworldsoft.com/#/user/center?lang=cn',
                    'en_url': 'https://junahotels-pcbooking.eworldsoft.com/#/user/center?lang=en',
                },
                {
                    'name': '文化宣传',
                    'en_name': 'Culture',
                    'cn_url': 'https://www.junahotels.com/cn/party_policy.html',
                    'en_url': 'https://www.junahotels.com/en/party_policy.html',
                },
                {
                    'name': '联系我们',
                    'en_name': 'Contact Us',
                    'cn_url': 'https://www.junahotels.com/cn/contact_us.html',
                    'en_url': 'https://www.junahotels.com/en/contact_us.html',
                },
            ]
            obj.city_list = [
                {
                    'code': 222,
                    'name': '无锡',
                    'en_name': 'Wuxi'
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 56,
                    'hotel_city': 222,
                    'hotel_cn_name': '无锡君来湖滨饭店',
                    'hotel_en_name': 'Juna Hubin Hotel',
                    'hotel_cn_address': '无锡市滨湖区环湖路1号（蠡园旁）',
                    'hotel_en_address': 'No.1 Huanhu Road, Wuxi City, China',
                    'hotel_tel': '+86 510 85101888',
                },
                {
                    'hotel_id': 1259,
                    'hotel_city': 222,
                    'hotel_cn_name': '无锡太湖饭店',
                    'hotel_en_name': 'Juna Taihu hotel',
                    'hotel_cn_address': '江苏省无锡市滨湖区锦园路1号',
                    'hotel_en_address': 'No.1 Jinyuan Road, Binhu District, Wuxi City, Jiangsu Province',
                    'hotel_tel': '+86 510 85517888',
                },
                {
                    'hotel_id': 657,
                    'hotel_city': 222,
                    'hotel_cn_name': '无锡君来梁溪饭店',
                    'hotel_en_name': 'Juna Liangxi Hotel',
                    'hotel_cn_address': '无锡梁溪区中山路177号',
                    'hotel_en_address': 'Juna Liangxi Hotel is located at 177 Zhongshan Road, Liangxi District, Wuxi City',
                    'hotel_tel': '+86 510 88683888',
                },
                {
                    'hotel_id': 1260,
                    'hotel_city': 222,
                    'hotel_cn_name': '无锡君来世尊酒店',
                    'hotel_en_name': 'World Hotel Grand Juna',
                    'hotel_cn_address': '江苏省无锡市滨湖区和风路111号',
                    'hotel_en_address': 'No.111, Hefeng Road, Binhu District, Wuxi City, Jiangsu Province, China',
                    'hotel_tel': '+86 510 85285888',
                },
                {
                    'hotel_id': 1261,
                    'hotel_city': 222,
                    'hotel_cn_name': '无锡君来洲际酒店',
                    'hotel_en_name': 'InterContinental Hotel',
                    'hotel_cn_address': '无锡梁溪区永和路6号',
                    'hotel_en_address': 'No.6 Yonghe Road, Wuxi City, Jiangsu Province, China',
                    'hotel_tel': '+86 510 81185888',
                },
            ];
            break;
        case 'syjhgjjr-pcbooking.eworldsoft.com':
            obj.hotel_id = 27;
            obj.hotel_type = 1;//1单体酒店
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://syjhgjjr.eworldsoft.com/api';
            obj.web_url = 'https://www.syjhgjjr.com';
            obj.hotel_logo = 'https://www.syjhgjjr.com/images/logo.png';
            obj.menu = [
                {
                    'name': '首页',
                    'en_name': 'Home',
                    'cn_url': 'https://www.syjhgjjr.com/cn/index.html',
                    'en_url': 'https://www.syjhgjjr.com/en/index.html',
                },
                {
                    'name': '关于酒店',
                    'en_name': 'About Us',
                    'cn_url': 'https://www.syjhgjjr.com/cn/about.html',
                    'en_url': 'https://www.syjhgjjr.com/en/about.html',
                },
                {
                    'name': '房型介绍',
                    'en_name': 'Rooms',
                    'cn_url': 'https://www.syjhgjjr.com/cn/procty.html',
                    'en_url': 'https://www.syjhgjjr.com/en/procty.html',
                },
                {
                    'name': '餐饮资讯',
                    'en_name': 'Restaurants',
                    'cn_url': 'https://www.syjhgjjr.com/cn/Catering.html',
                    'en_url': 'https://www.syjhgjjr.com/en/Catering.html',
                },
                {
                    'name': '会议宴会',
                    'en_name': 'Meetings',
                    'cn_url': 'https://www.syjhgjjr.com/cn/metting.html',
                    'en_url': 'https://www.syjhgjjr.com/en/metting.html',
                },
                {
                    'name': '康乐休闲',
                    'en_name': 'Leisure',
                    'cn_url': 'https://www.syjhgjjr.com/cn/fun.html',
                    'en_url': 'https://www.syjhgjjr.com/en/fun.html',
                },
                {
                    'name': '会员中心',
                    'en_name': 'Member',
                    'cn_url': '',
                    'en_url': '',
                },
                {
                    'name': '图片与视频',
                    'en_name': 'Pictures',
                    'cn_url': 'https://www.syjhgjjr.com/cn/video.html',
                    'en_url': 'https://www.syjhgjjr.com/en/video.html',
                },
                {
                    'name': '酒店动态',
                    'en_name': 'News',
                    'cn_url': 'https://www.syjhgjjr.com/cn/recruit.html',
                    'en_url': 'https://www.syjhgjjr.com/en/recruit.html',
                },
                {
                    'name': '周边景区',
                    'en_name': 'Tourism',
                    'cn_url': 'https://www.syjhgjjr.com/cn/around.html',
                    'en_url': 'https://www.syjhgjjr.com/en/around.html',
                },
            ]
            obj.city_list = [
                {
                    'code': '',
                    'name': '',
                    'en_name': ''
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 20,
                    'hotel_city': '',
                    'hotel_cn_name': '三亚京海国际假日酒店',
                    'hotel_en_name': 'Jinghai Hotel & Resort',
                    'hotel_cn_address': '海南省三亚市三亚湾路139号',
                    'hotel_en_address': 'Sanya Bay Road #139, Sanya City, Hainan Province, China',
                    'hotel_tel': '+86 898 88611888',
                }
            ];
            break;
        case 'liangyuanhotel-pcbooking.eworldsoft.com':
            obj.hotel_id = 24;
            obj.hotel_type = 1;//1单体酒店
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://liangyuanhotel.eworldsoft.com/api';
            obj.web_url = 'http://www.liangyuanhotel.com.cn';
            obj.hotel_logo = 'https://mp-img.eworldsoft.com/eworld/images/logo-liangyuanhotel.png';
            obj.menu = [
                {
                    'name': '首页',
                    'en_name': 'Home',
                    'url': 'https://www.liangyuanhotel.com.cn'
                },
                {
                    'name': '在线预订',
                    'en_name': 'Booking',
                    'url': 'https://www.liangyuanhotel.com.cn'
                },
                {
                    'name': '关于我们',
                    'en_name': 'About',
                    'url': 'http://www.liangyuanhotel.com.cn/info.html'
                },
                {
                    'name': '客房设施',
                    'en_name': 'Rooms',
                    'url': 'https://liangyuanhotel.com.cn/guestroom.html'
                },
                {
                    'name': '餐饮设施',
                    'en_name': 'Restaurant',
                    'url': 'https://liangyuanhotel.com.cn/dining.html'
                },
                {
                    'name': '会议设施',
                    'en_name': 'Meeting',
                    'url': 'https://liangyuanhotel.com.cn/conference.html'
                },
                {
                    'name': '康体娱乐',
                    'en_name': 'Entertainment',
                    'url': 'https://liangyuanhotel.com.cn/leisure.html'
                },
                {
                    'name': '新闻中心',
                    'en_name': 'News',
                    'url': 'https://liangyuanhotel.com.cn/news.html'
                },
            ]
            obj.city_list = [
                {
                    'code': '',
                    'name': '',
                    'en_name': ''
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 24,
                    'hotel_city': '',
                    'hotel_cn_name': '安港良苑酒店',
                    'hotel_en_name': '',
                    'hotel_cn_address': '安徽省合肥市芜湖路319号',
                    'hotel_en_address': '',
                    'hotel_tel': '+86 551 62288888',
                }
            ];
            break;
        case 'kinhohotel-pcbooking.eworldsoft.com':
            obj.hotel_id = 21;
            obj.hotel_type = 1;//1单体酒店
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://kinhohotel.eworldsoft.com/api';
            obj.web_url = 'https://www.kinhohotel.com';
            obj.hotel_logo = 'https://mp-img.eworldsoft.com/eworld/images/logo-kinhohotel.jpg';
            obj.menu = [
                {
                    'name': '主页',
                    'en_name': 'Home Page',
                    'cn_url': 'https://www.kinhohotel.com/home.html',
                    'en_url': 'https://www.kinhohotel.com/en/home.html',
                },
                {
                    'name': '酒店概况',
                    'en_name': 'Hotel Profile',
                    'cn_url': 'https://www.kinhohotel.com/info.html',
                    'en_url': 'https://www.kinhohotel.com/en/info.html',
                },
                {
                    'name': '客房与套房',
                    'en_name': 'Guest Rooms & Suites',
                    'cn_url': 'https://www.kinhohotel.com/room.html',
                    'en_url': 'https://www.kinhohotel.com/en/room.html',
                },
                {
                    'name': '天豪美食',
                    'en_name': 'Kinho Food',
                    'cn_url': 'https://www.kinhohotel.com/dining.html',
                    'en_url': 'https://www.kinhohotel.com/en/dining.html',
                },
                {
                    'name': '精选优惠',
                    'en_name': 'Special Offers',
                    'cn_url': 'https://www.kinhohotel.com/promotions.html',
                    'en_url': 'https://www.kinhohotel.com/en/promotions.html',
                },
                {
                    'name': '宴会及会议',
                    'en_name': 'Banquet & Meeting',
                    'cn_url': 'https://www.kinhohotel.com/conference.html',
                    'en_url': 'https://www.kinhohotel.com/en/conference.html',
                },
                {
                    'name': '休闲娱乐',
                    'en_name': 'Leisure & Recreation',
                    'cn_url': 'https://www.kinhohotel.com/recreation.html',
                    'en_url': 'https://www.kinhohotel.com/en/recreation.html',
                },
                {
                    'name': '天豪汇荣誉会员',
                    'en_name': 'Honorary Members',
                    'cn_url': 'https://www.kinhohotel.com/member.html',
                    'en_url': 'https://www.kinhohotel.com/en/member.html',
                },
                {
                    'name': '联系我们',
                    'en_name': 'Contact Us',
                    'cn_url': 'https://www.kinhohotel.com/contact.html',
                    'en_url': 'https://www.kinhohotel.com/en/contact.html',
                },
            ]
            obj.city_list = [
                {
                    'code': '',
                    'name': '',
                    'en_name': ''
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 21,
                    'hotel_city': '',
                    'hotel_cn_name': '温州天豪君澜大酒店',
                    'hotel_en_name': 'Wenzhou Narada Grand Hotel',
                    'hotel_cn_address': '中国浙江温州乐清市千帆东路277号（四环路南草垟）',
                    'hotel_en_address': ' 277#, East Qianfan Road, Yueqing City, Wenzhou, China',
                    'hotel_tel': '+86 577 61199999',
                }
            ];
            break;
        case 'baishanhotel-pcbooking.eworldsoft.com':
            obj.hotel_id = 33;//酒店ID
            obj.hotel_type = 1;//1单体酒店 2集团酒店
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://baishanhotel.eworldsoft.com/api';
            obj.web_url = 'https://www.baishanhotel.com/';
            obj.hotel_logo = 'https://www.baishanhotel.com/images/3819e8ad55bb3bb5b0008f95a9e30ebb.png';
            obj.menu = [
                {
                    'name': '首页',
                    'en_name': 'Home',
                    'cn_url': 'https://www.baishanhotel.com/index.html',
                    'en_url': 'https://www.baishanhotel.com/en/index.html',
                },
                {
                    'name': '酒店介绍',
                    'en_name': 'Introduce',
                    'cn_url': 'https://www.baishanhotel.com/index.html#hotel-info',
                    'en_url': 'https://www.baishanhotel.com/en/index.html#hotel-info',
                },
                {
                    'name': '酒店新闻',
                    'en_name': 'News',
                    'cn_url': 'https://www.baishanhotel.com/news.html',
                    'en_url': 'https://www.baishanhotel.com/en/news.html',
                },
                {
                    'name': '文明旅游',
                    'en_name': 'Tourism',
                    'cn_url': 'https://www.baishanhotel.com/promotion.html',
                    'en_url': 'https://www.baishanhotel.com/en/promotion.html',
                },
                {
                    'name': '客房设施',
                    'en_name': 'Room Facilities',
                    'cn_url': 'https://www.baishanhotel.com/rooms.html',
                    'en_url': 'https://www.baishanhotel.com/en/rooms.html',
                },
                {
                    'name': '餐饮美食',
                    'en_name': 'Dining Facilities',
                    'cn_url': 'https://www.baishanhotel.com/food.html',
                    'en_url': 'https://www.baishanhotel.com/en/food.html',
                },
                {
                    'name': '会议服务',
                    'en_name': 'Meeting Facilities',
                    'cn_url': 'https://www.baishanhotel.com/meeting.html',
                    'en_url': 'https://www.baishanhotel.com/en/meeting.html',
                },
                {
                    'name': '其他服务',
                    'en_name': 'Travel and leisure',
                    'cn_url': 'https://www.baishanhotel.com/other.html',
                    'en_url': 'https://www.baishanhotel.com/en/other.html',
                },
                {
                    'name': '周边环境',
                    'en_name': 'Environment',
                    'cn_url': 'https://www.baishanhotel.com/surrounding.html',
                    'en_url': 'https://www.baishanhotel.com/en/surrounding.html'
                },
            ]
            obj.city_list = [
                {
                    'code': 1826,
                    'name': '延吉',
                    'en_name': 'Yanji'
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 33,
                    'hotel_city': 1826,
                    'hotel_cn_name': '延边白山大厦酒店',
                    'hotel_en_name': 'Baishan Mansion Hotel, Yanbian',
                    'hotel_cn_address': '吉林省延吉市友谊路66号',
                    'hotel_en_address': 'No. 66 Youyi road, Yanji city, Jilin province, China',
                    'hotel_tel': '+86 433 2588888',
                },
            ];
            break;
        case 'zhejianghotel-pcbooking.eworldsoft.com':
            obj.hotel_id = 75;
            obj.hotel_type = 1;//1单体酒店
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://zhejianghotel.eworldsoft.com/api';
            obj.web_url = 'http://www.zhejianghotel.com';
            obj.hotel_logo = '';
            obj.menu = [
                {
                    'name': '关于宾馆',
                    'en_name': 'About the Hotel',
                    'cn_url': 'http://www.zhejianghotel.com/cn/hotelinfo.html',
                    'en_url': 'http://www.zhejianghotel.com/en/hotelinfo.html'
                },
                {
                    'name': '客房服务',
                    'en_name': 'Room Services',
                    'cn_url': 'http://www.zhejianghotel.com/cn/guestroom.html',
                    'en_url': 'http://www.zhejianghotel.com/en/guestroom.html',
                },
                {
                    'name': '餐饮服务',
                    'en_name': 'Food and Beverage Services',
                    'cn_url': 'http://www.zhejianghotel.com/cn/restaurant.html',
                    'en_url': 'http://www.zhejianghotel.com/en/restaurant.html',
                },
                {
                    'name': '会议服务',
                    'en_name': 'Conference Services',
                    'cn_url': 'http://www.zhejianghotel.com/cn/meeting.html',
                    'en_url': 'http://www.zhejianghotel.com/en/meeting.html',
                },
                {
                    'name': '娱乐购物',
                    'en_name': 'Entertainment and Shopping',
                    'cn_url': 'http://www.zhejianghotel.com/cn/sportscenter.html',
                    'en_url': 'http://www.zhejianghotel.com/en/sportscenter.html',
                },
                {
                    'name': '704工程',
                    'en_name': '704 Project',
                    'cn_url': 'http://www.zhejianghotel.com/cn/704gongcheng.html',
                    'en_url': 'http://www.zhejianghotel.com/en/704gongcheng.html',
                },
                {
                    'name': '自由行宝典',
                    'en_name': 'Free Walker Book',
                    'cn_url': 'http://www.zhejianghotel.com/cn/zijia.html',
                    'en_url': 'http://www.zhejianghotel.com/en/zijia.html',
                },
                {
                    'name': '联系我们',
                    'en_name': 'Contact Us',
                    'cn_url': 'http://www.zhejianghotel.com/cn/findus.html',
                    'en_url': 'http://www.zhejianghotel.com/en/findus.html',
                },
            ]
            obj.city_list = [
                {
                    'code': 383,
                    'name': '杭州',
                    'en_name': 'Hang Zhou'
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 75,
                    'hotel_city': 383,
                    'hotel_cn_name': '浙江宾馆',
                    'hotel_en_name': 'Zhejiang Hotel',
                    'hotel_cn_address': '杭州三台山路278号',
                    'hotel_en_address': 'No. 278, Santaishan Road, Hangzhou, China',
                    'hotel_tel': '+86 571 87971904',
                }
            ];
            break;
        case 'waterfallguilin-pcbooking.eworldsoft.com':
            obj.hotel_id = 32;
            obj.hotel_type = 1;//2集团
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://waterfallguilin.eworldsoft.com/api';
            obj.web_url = 'https://www.waterfallguilin.com';
            obj.hotel_logo = 'https://www.waterfallguilin.com/image/logo.png';
            obj.menu = [
                {
                    'name': '首页',
                    'en_name': 'Home',
                    'url': 'https://www.waterfallguilin.com'
                }
            ]
            obj.city_list = [
                {
                    'code': 98,
                    'name': '桂林',
                    'en_name': 'Guilin'
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 32,
                    'hotel_city': 98,
                    'hotel_cn_name': '桂林漓江大瀑布饭店',
                    'hotel_en_name': 'Guilin Lijiang Waterfall Hotel',
                    'hotel_cn_address': '桂林市杉湖北路一号',
                    'hotel_en_address': '1North Shanhu Road Guilin,China',
                    'hotel_tel': '+86 773-2822881',
                },
            ];
            break;
        case 'huafaplace-pcbooking.eworldsoft.com':
            obj.hotel_id = 9;//酒店ID
            obj.hotel_type = 1;//1单体酒店 2集团酒店
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://huafaplace.eworldsoft.com/api';
            obj.web_url = 'https://www.huafaplace.com';
            obj.hotel_logo = 'https://www.huafaplace.com/images/logo.png';
            obj.menu = [
                {
                    'name': '首页',
                    'en_name': 'Home',
                    'cn_url': 'https://www.huafaplace.com/cn/index.html',
                    'en_url': 'https://www.huafaplace.com/en/index.html',
                },
                {
                    'name': '关于我们',
                    'en_name': 'About Us',
                    'cn_url': 'https://www.huafaplace.com/cn/aboutUs.html',
                    'en_url': 'https://www.huafaplace.com/en/aboutUs.html',
                },
                {
                    'name': '酒店介绍',
                    'en_name': 'Hotel Profile',
                    'cn_url': 'https://www.huafaplace.com/cn/city-about.html',
                    'en_url': 'https://www.huafaplace.com/en/city-about.html',
                },
                {
                    'name': '温馨客房',
                    'en_name': 'Rooms',
                    'cn_url': 'https://www.huafaplace.com/cn/room.html',
                    'en_url': 'https://www.huafaplace.com/en/room.html',
                },
                {
                    'name': '会议展览',
                    'en_name': 'Meetings',
                    'cn_url': 'https://www.huafaplace.com/cn/meetimg.html',
                    'en_url': 'https://www.huafaplace.com/en/meetimg.html',
                },
                {
                    'name': '酒店周边',
                    'en_name': 'Tourism',
                    'cn_url': 'https://www.huafaplace.com/cn/travel.html',
                    'en_url': 'https://www.huafaplace.com/en/travel.html',
                },
                {
                    'name': '联系我们',
                    'en_name': 'Contact Us',
                    'cn_url': 'https://www.huafaplace.com/cn/conactUs.html',
                    'en_url': 'https://www.huafaplace.com/en/conactUs.html',
                },
            ]
            obj.city_list = [
                {
                    'code': 96,
                    'name': '珠海',
                    'en_name': 'Zhuhai'
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 9,
                    'hotel_city': 96,
                    'hotel_cn_name': '华发·会展行政公寓',
                    'hotel_en_name': 'HUAFA PLACE',
                    'hotel_cn_address': '中国珠海市湾仔银湾路1663号公寓式酒店',
                    'hotel_en_address': 'Address：B Zone No.1663,Yinwan Road,Wanzai Block,Zhuhai',
                    'hotel_tel': '+86 756-6819999',
                },
            ];
            break;
        case 'yangshuohotel-pcbooking.eworldsoft.com':
            obj.hotel_id = 816;//酒店ID
            obj.hotel_type = 1;//1单体酒店 2集团酒店
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://yangshuogreenlotushotel.eworldsoft.com/api';
            obj.web_url = 'http://www.yangshuogreenlotushotel.com';
            obj.hotel_logo = '';
            obj.menu = [
                {
                    'name': '酒店简介',
                    'en_name': 'Hotel Overview',
                    'cn_url': 'http://www.yangshuogreenlotushotel.com/cn/about.html',
                    'en_url': 'http://www.yangshuogreenlotushotel.com/en/about.html',
                },
                {
                    'name': '温馨客房',
                    'en_name': 'Rooms',
                    'cn_url': 'http://www.yangshuogreenlotushotel.com/cn/roomlist_6.html',
                    'en_url': 'http://www.yangshuogreenlotushotel.com/en/roomlist_6.html',
                },
                {
                    'name': '餐饮美食',
                    'en_name': 'Restaurants',
                    'cn_url': 'http://www.yangshuogreenlotushotel.com/cn/dining-halls.html',
                    'en_url': 'http://www.yangshuogreenlotushotel.com/en/dining-halls.html',
                },
                {
                    'name': '会议宴会',
                    'en_name': 'Meetings',
                    'cn_url': 'http://www.yangshuogreenlotushotel.com/cn/meeting-rooms.html',
                    'en_url': 'http://www.yangshuogreenlotushotel.com/en/meeting-rooms.html',
                },
                {
                    'name': '休闲娱乐',
                    'en_name': 'Leisure',
                    'cn_url': 'http://www.yangshuogreenlotushotel.com/cn/recreation.html',
                    'en_url': 'http://www.yangshuogreenlotushotel.com/en/recreation.html',
                },
                {
                    'name': '周边景点',
                    'en_name': 'Tourism',
                    'cn_url': 'http://www.yangshuogreenlotushotel.com/cn/attractions-list.html',
                    'en_url': 'http://www.yangshuogreenlotushotel.com/en/attractions-list.html',
                },
                {
                    'name': '关于我们',
                    'en_name': 'About Us',
                    'cn_url': 'http://www.yangshuogreenlotushotel.com/cn/contact-us.html',
                    'en_url': 'http://www.yangshuogreenlotushotel.com/en/contact-us.html',
                },
            ]
            obj.city_list = [
                {
                    'code': 98,
                    'name': '桂林',
                    'en_name': 'Guilin'
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 816,
                    'hotel_city': 98,
                    'hotel_cn_name': '阳朔碧莲江景大酒店',
                    'hotel_en_name': 'Yangshuo Green Lotus Hotel',
                    'hotel_cn_address': '广西桂林市阳朔县观莲路1号',
                    'hotel_en_address': 'Add:NO.1 Guanlian Road Yangshuo,Guilin,Guangxi Zhuang Autonomous Region,China.',
                    'hotel_tel': '+86 773 8886666',
                },
            ];
            break;
        case 'bjegicc-pcbooking.eworldsoft.com':
            obj.hotel_id = 1283;//酒店ID
            obj.hotel_type = 1;//1单体酒店 2集团酒店
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://bjegicc.eworldsoft.com/api';
            obj.web_url = 'http://www.bjegicc.com';
            obj.hotel_logo = '';
            obj.menu = [
                {
                    'name': '网站首页',
                    'en_name': 'Index',
                    'cn_url': 'http://www.bjegicc.com/cn/index.html',
                    'en_url': 'http://www.bjegicc.com/en/index.html',
                },
                {
                    'name': '关于酒店',
                    'en_name': 'About',
                    'cn_url': 'http://www.bjegicc.com/cn/about.html',
                    'en_url': 'http://www.bjegicc.com/en/about.html',
                },
                {
                    'name': '客房/别墅',
                    'en_name': 'Room&Suite',
                    'cn_url': 'http://www.bjegicc.com/cn/room_4.html',
                    'en_url': 'http://www.bjegicc.com/en/room_4.html',
                },
                {
                    'name': '会议宴会',
                    'en_name': 'Conference&Banquet',
                    'cn_url': 'http://www.bjegicc.com/cn/Meeting_1.html',
                    'en_url': 'http://www.bjegicc.com/en/Meeting_1.html',
                },
                {
                    'name': '美食',
                    'en_name': 'F&B',
                    'cn_url': 'http://www.bjegicc.com/cn/Restaurant_1.html',
                    'en_url': 'http://www.bjegicc.com/en/Restaurant_1.html',
                },
                {
                    'name': '婚宴',
                    'en_name': 'Wedding',
                    'cn_url': 'http://www.bjegicc.com/cn/Entertainment.html',
                    'en_url': 'http://www.bjegicc.com/en/Entertainment.html',
                },
                {
                    'name': '健康/休闲',
                    'en_name': 'Health&Leisure',
                    'cn_url': 'http://www.bjegicc.com/cn/health.html',
                    'en_url': 'http://www.bjegicc.com/en/health.html',
                },
                {
                    'name': '联系我们',
                    'en_name': 'Contact',
                    'cn_url': 'http://www.bjegicc.com/cn/Contactus.html',
                    'en_url': 'http://www.bjegicc.com/en/Contactus.html',
                },
            ]
            obj.city_list = [
                {
                    'code': 52,
                    'name': '北京',
                    'en_name': 'Beijing'
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 1283,
                    'hotel_city': 52,
                    'hotel_cn_name': '北京金茂怡生园酒店',
                    'hotel_en_name': 'JINMAO EASTERN GARDEN HOTEL',
                    'hotel_cn_address': '北京市顺义区北小营镇左堤路5号',
                    'hotel_en_address': 'no. 5, zuodi road, beixiaoying town, shunyi district, Beijing',
                    'hotel_tel': '+86 10 60486712',
                },
            ];
            break;
        case 'rishenghotel-pcbooking.eworldsoft.com':
            obj.hotel_id = 28;//酒店ID
            obj.hotel_type = 1;//1单体酒店 2集团酒店
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://rishenghotel.eworldsoft.com/api';
            obj.web_url = 'https://www.rishenghotel.com';
            obj.hotel_logo = 'https://www.rishenghotel.com/image/logo.png';
            obj.menu = [
                {
                    'name': '首页',
                    'en_name': 'Index',
                    'cn_url': 'https://www.rishenghotel.com/index.html',
                    'en_url': 'https://www.rishenghotel.com/en/index.html',
                },
                {
                    'name': '关于我们',
                    'en_name': 'About Us',
                    'cn_url': 'https://www.rishenghotel.com/about.html',
                    'en_url': 'https://www.rishenghotel.com/en/about.html',
                },
                {
                    'name': '酒店商城',
                    'en_name': 'Shoping',
                    'cn_url': 'https://www.rishenghotel.com/shoping.html',
                    'en_url': 'https://www.rishenghotel.com/en/shoping.html',
                },
                {
                    'name': '餐饮美食',
                    'en_name': 'Food and Beverage',
                    'cn_url': 'https://www.rishenghotel.com/food.html',
                    'en_url': 'https://www.rishenghotel.com/en/food.html',
                },
                {
                    'name': '温馨客房',
                    'en_name': 'RoomSuite',
                    'cn_url': 'https://www.rishenghotel.com/house.html',
                    'en_url': 'https://www.rishenghotel.com/en/house.html',
                },
                {
                    'name': '宴会会议',
                    'en_name': 'ConferenceBanquet',
                    'cn_url': 'https://www.rishenghotel.com/meeting.html',
                    'en_url': 'https://www.rishenghotel.com/en/meeting.html',
                },
                {
                    'name': '休闲娱乐',
                    'en_name': 'HealthLeisure',
                    'cn_url': 'https://www.rishenghotel.com/entertainment.html',
                    'en_url': 'https://www.rishenghotel.com/en/entertainment.html',
                },
                {
                    'name': '人才招聘',
                    'en_name': 'Recruitment',
                    'cn_url': 'https://www.rishenghotel.com/people.html',
                    'en_url': 'https://www.rishenghotel.com/en/people.html',
                },
                {
                    'name': '联系我们',
                    'en_name': 'Contact',
                    'cn_url': 'https://www.rishenghotel.com/contact.html',
                    'en_url': 'https://www.rishenghotel.com/en/contact.html',
                },
            ]
            obj.city_list = [
                {
                    'code': 222,
                    'name': '无锡',
                    'en_name': 'Wuxi'
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 28,
                    'hotel_city': 222,
                    'hotel_cn_name': '无锡日晟国际酒店',
                    'hotel_en_name': 'WuXi R-SUN INTERNATIONAL HOTEL',
                    'hotel_cn_address': '江苏无锡新区长江北路1号',
                    'hotel_en_address': 'Wuxi New District, Jiangsu Yangtze River Road on the 1st',
                    'hotel_tel': '+86 510 81819999',
                },
            ];
            break;
        case 'gucunparkhotel-pcbooking.eworldsoft.com':
            obj.hotel_id = 1168;//酒店ID
            obj.hotel_type = 1;//1单体酒店 2集团酒店
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://gucunparkhotel.eworldsoft.com/api';
            obj.web_url = 'https://www.gucunparkhotel.com';
            obj.hotel_logo = 'https://www.gucunparkhotel.com/images/logo.png';
            obj.menu = [
                {
                    'name': '首页',
                    'en_name': 'Index',
                    'cn_url': 'https://www.gucunparkhotel.com/index.html',
                    'en_url': 'https://www.gucunparkhotel.com/en/index.html',
                },
                {
                    'name': '酒店介绍',
                    'en_name': 'About Us',
                    'cn_url': 'https://www.gucunparkhotel.com/aboutus.html',
                    'en_url': 'https://www.gucunparkhotel.com/en/aboutus.html',
                },
                {
                    'name': '客房设施',
                    'en_name': 'Room',
                    'cn_url': 'https://www.gucunparkhotel.com/guestroom.html',
                    'en_url': 'https://www.gucunparkhotel.com/en/guestroom.html',
                },
                {
                    'name': '餐饮设施',
                    'en_name': 'Dining',
                    'cn_url': 'https://www.gucunparkhotel.com/dining.html',
                    'en_url': 'https://www.gucunparkhotel.com/en/dining.html',
                },
                {
                    'name': '会议宴会',
                    'en_name': 'Meeting',
                    'cn_url': 'https://www.gucunparkhotel.com/meeting.html',
                    'en_url': 'https://www.gucunparkhotel.com/en/meeting.html',
                },
                {
                    'name': '康体娱乐',
                    'en_name': 'Leisure',
                    'cn_url': 'https://www.gucunparkhotel.com/leisure.html',
                    'en_url': 'https://www.gucunparkhotel.com/en/leisure.html',
                },
                {
                    'name': '周边环境',
                    'en_name': 'Periphery',
                    'cn_url': 'https://www.gucunparkhotel.com/zb.html',
                    'en_url': 'https://www.gucunparkhotel.com/en/zb.html',
                },
                {
                    'name': '联系我们',
                    'en_name': 'Contact Us',
                    'cn_url': 'https://www.gucunparkhotel.com/contactus.html',
                    'en_url': 'https://www.gucunparkhotel.com/en/contactus.html',
                },
            ]
            obj.city_list = [
                {
                    'code': 310,
                    'name': '上海',
                    'en_name': 'Shang hai'
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 1168,
                    'hotel_city': 310,
                    'hotel_cn_name': '上海衡山北郊宾馆',
                    'hotel_en_name': 'GuCun Park Hotel',
                    'hotel_cn_address': '上海市宝山区沪太路4788号',
                    'hotel_en_address': 'No. 4788, Hutai Road, Baoshan District, Shanghai',
                    'hotel_tel': '+86 21 56040088',
                },
            ];
            break;
        case 'zjih-pcbooking.eworldsoft.com':
            obj.hotel_id = 58;//酒店ID
            obj.hotel_type = 1;//1单体酒店 2集团酒店
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://zjih.eworldsoft.com/api';
            obj.web_url = 'http://www.zjih.com';
            obj.hotel_logo = 'https://zjih-pcbooking.eworldsoft.com/other/zjih-com-logo.png';
            obj.menu = [
                {
                    'name': '首页',
                    'en_name': 'Home',
                    'cn_url': 'http://www.zjih.com/index.html',
                    'en_url': 'http://www.zjih.com/en/index.html',
                },
                {
                    'name': '酒店介绍',
                    'en_name': 'Introduce',
                    'cn_url': 'http://www.zjih.com/index.html#hotel-info',
                    'en_url': 'http://www.zjih.com/en/index.html#hotel-info',
                },
                {
                    'name': '新闻促销',
                    'en_name': 'News Promotion',
                    'cn_url': 'http://www.zjih.com/news.html',
                    'en_url': 'http://www.zjih.com/en/news.html',
                },
                {
                    'name': '客房设施',
                    'en_name': 'Room Facilities',
                    'cn_url': 'http://www.zjih.com/rooms.html',
                    'en_url': 'http://www.zjih.com/en/rooms.html',
                },
                {
                    'name': '餐饮设施',
                    'en_name': 'Dining Facilities',
                    'cn_url': 'http://www.zjih.com/food.html',
                    'en_url': 'http://www.zjih.com/en/food.html',
                },
                {
                    'name': '会议宴会',
                    'en_name': 'Meeting Facilities',
                    'cn_url': 'http://www.zjih.com/meeting.html',
                    'en_url': 'http://www.zjih.com/en/meeting.html',
                },
                {
                    'name': '康乐设施',
                    'en_name': 'Other Service',
                    'cn_url': 'http://www.zjih.com/other.html',
                    'en_url': 'http://www.zjih.com/en/other.html',
                },
                {
                    'name': '其他服务',
                    'en_name': 'Other Service',
                    'cn_url': 'http://www.zjih.com/other.html',
                    'en_url': 'http://www.zjih.com/en/other.html',
                },
                {
                    'name': '周边环境',
                    'en_name': 'Environment',
                    'cn_url': 'http://www.zjih.com/surrounding.html',
                    'en_url': 'http://www.zjih.com/en/surrounding.html',
                },
            ]
            obj.city_list = [
                {
                    'code': 383,
                    'name': '杭州',
                    'en_name': 'Hang Zhou'
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 58,
                    'hotel_city': 383,
                    'hotel_cn_name': '浙江国际雷迪森广场酒店',
                    'hotel_en_name': 'Landison Plaza International Hotel Zhejiang',
                    'hotel_cn_address': '中国杭州体育场路221号',
                    'hotel_en_address': 'No. 221 Tiyuchang Road, Hangzhou, China.',
                    'hotel_tel': '+86 571-85770088',
                },
            ];
            break;
        case 'mcmchotel-pcbooking.eworldsoft.com':
            obj.hotel_id = 20;//酒店ID
            obj.hotel_type = 1;//1单体酒店 2集团酒店
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://mcmchotel.eworldsoft.com/api';
            obj.web_url = 'https://www.mcmchotel.com/';
            obj.hotel_logo = 'https://www.mcmchotel.com/images/78c38c0f37b9cda30a565747350aa049.png';
            obj.menu = [
                {
                    'name': '首页',
                    'en_name': 'Home',
                    'cn_url': 'https://www.mcmchotel.com/index.html',
                    'en_url': 'https://www.mcmchotel.com/en/index.html',
                },
                {
                    'name': '酒店介绍',
                    'en_name': 'Introduce',
                    'cn_url': 'https://www.mcmchotel.com/index.html#hotel-info',
                    'en_url': 'https://www.mcmchotel.com/en/index.html#hotel-info',
                },
                {
                    'name': '新闻促销',
                    'en_name': 'News Promotion',
                    'cn_url': 'https://www.mcmchotel.com/news.html',
                    'en_url': 'https://www.mcmchotel.com/en/news.html',
                },
                {
                    'name': '电子券',
                    'en_name': 'Electronic ticket',
                    'cn_url': 'https://www.mcmchotel.com/shopping.html',
                    'en_url': 'https://www.mcmchotel.com/en/shopping.html',
                },
                {
                    'name': '客房设施',
                    'en_name': 'Room Facilities',
                    'cn_url': 'https://www.mcmchotel.com/rooms.html',
                    'en_url': 'https://www.mcmchotel.com/en/rooms.html',
                },
                {
                    'name': '餐饮设施',
                    'en_name': 'Dining Facilities',
                    'cn_url': 'https://www.mcmchotel.com/food.html',
                    'en_url': 'https://www.mcmchotel.com/en/food.html',
                },
                {
                    'name': '会议设施',
                    'en_name': 'Meeting Facilities',
                    'cn_url': 'https://www.mcmchotel.com/meeting.html',
                    'en_url': 'https://www.mcmchotel.com/en/meeting.html',
                },
                {
                    'name': '其他服务',
                    'en_name': 'Other Service',
                    'cn_url': 'https://www.mcmchotel.com/other.html',
                    'en_url': 'https://www.mcmchotel.com/en/other.html',
                },
                {
                    'name': '周边环境',
                    'en_name': 'Environment',
                    'cn_url': 'https://www.mcmchotel.com/surrounding.html',
                    'en_url': 'https://www.mcmchotel.com/en/surrounding.html',
                },
            ]
            obj.city_list = [
                {
                    'code': 383,
                    'name': '杭州',
                    'en_name': 'Hang Zhou'
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 20,
                    'hotel_city': 383,
                    'hotel_cn_name': '杭州马可·波罗假日酒店',
                    'hotel_en_name': 'Shang zhou Merchant Marco Hotel',
                    'hotel_cn_address': '中国浙江省杭州市上城区平海路38号',
                    'hotel_en_address': '38 pinghai road, shangcheng district, hangzhou city, zhejiang province, China',
                    'hotel_tel': '+86 571-87018888',
                },
            ];
            break;
        case 'xuanwu-pcbooking.eworldsoft.com':
            obj.hotel_id = 1015;
            obj.hotel_type = 1;//1单体酒店
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://xuanwu.eworldsoft.com/api';
            obj.web_url = 'https://xuanwu.com.cn';
            obj.hotel_logo = 'https://xuanwu.com.cn/images/logo.png';
            obj.menu = [
                {
                    'name': '概况',
                    'en_name': 'Home',
                    'url': ''
                },
                {
                    'name': '精选客房',
                    'en_name': 'About Us',
                    'url': ''
                },
                {
                    'name': '食全食美',
                    'en_name': 'Hotel Profile',
                    'url': ''
                },
                {
                    'name': '客房预订',
                    'en_name': 'Rooms',
                    'url': ''
                },
                {
                    'name': '快速预订',
                    'en_name': 'Meetings',
                    'url': ''
                },
                {
                    'name': '娱乐休闲',
                    'en_name': 'Tourism',
                    'url': ''
                },
                {
                    'name': '宴会及活动',
                    'en_name': 'Contact Us',
                    'url': ''
                },
                {
                    'name': '酒店商城',
                    'en_name': 'Contact Us',
                    'url': ''
                },
                {
                    'name': '团购',
                    'en_name': 'Contact Us',
                    'url': ''
                },
                {
                    'name': '秒杀',
                    'en_name': 'Contact Us',
                    'url': ''
                },
                {
                    'name': '新闻',
                    'en_name': 'Contact Us',
                    'url': ''
                },
                {
                    'name': '贵宾会',
                    'en_name': 'Contact Us',
                    'url': ''
                },
                {
                    'name': 'Worldhotels',
                    'en_name': 'Contact Us',
                    'url': ''
                },
            ]
            obj.city_list = [
                {
                    'code': 220,
                    'name': '南京',
                    'en_name': 'Nanjing'
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 1015,
                    'hotel_city': 220,
                    'hotel_cn_name': '南京玄武饭店',
                    'hotel_en_name': 'Lakeview Xuanwu Hotel',
                    'hotel_cn_address': '南京市中央路193号',
                    'hotel_en_address': '193 Zhongyang Road, Nanjing, Jiangsu, China',
                    'hotel_tel': '+86 25 83358888',
                }
            ];
            break;
        case 'sxc-pcbooking.eworldsoft.com':
            obj.hotel_id = 927;
            obj.hotel_type = 1;//2集团
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://sxc.eworldsoft.com/api';
            obj.web_url = 'https://www.youtinghotel.com/';
            obj.hotel_logo = 'https://www.youtinghotel.com/images/logo.png';
            obj.menu = [
                {
                    'name': '探',
                    'en_name': '探',
                    'url': 'https://www.youtinghotel.com/cn/index.html'
                },
                {
                    'name': '庭',
                    'en_name': '庭',
                    'url': 'https://www.youtinghotel.com/cn/rooms.html'
                },
                {
                    'name': '食',
                    'en_name': '食',
                    'url': 'https://www.youtinghotel.com/cn/shi.html'
                },
                {
                    'name': '游',
                    'en_name': '游',
                    'url': 'https://www.youtinghotel.com/cn/you.html'
                },
                {
                    'name': '购',
                    'en_name': '购',
                    'url': 'https://www.youtinghotel.com/cn/shopping.html'
                },
                {
                    'name': '位置',
                    'en_name': '位置',
                    'url': 'https://www.youtinghotel.com/cn/map.html'
                },
                {
                    'name': '游悠·庭家人',
                    'en_name': '游悠·庭家人',
                    'url': 'https://www.youtinghotel.com/cn/integral.html'
                },
            ]
            obj.city_list = [
                {
                    'code': 321,
                    'name': '上海',
                    'en_name': 'shang hai'
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 927,
                    'hotel_city': 321,
                    'hotel_cn_name': '上海悠庭皇家驿栈',
                    'hotel_en_name': 'Shanghai Youting Royal Posthouse',
                    'hotel_cn_address': '上海市浦东新区新场镇港西街6号(石笋街82号桥下)',
                    'hotel_en_address': 'Shanghai Pudong New Area, Xinchang Town, Gangxi Street No. 6',
                    'hotel_tel': '+86 21-68171370',
                },
                /*{
                    'hotel_id': 1326,
                    'hotel_city': 310,
                    'hotel_cn_name': '上海璞素上居民宿',
                    'hotel_en_name': 'Shanghai Pu Su Shang Residential Accommodation',
                    'hotel_cn_address': '上海市浦东新区新场镇蒋桥村1539号',
                    'hotel_en_address': 'Shanghai Pudong New Area, Xinchang Town, Jiangqiao Village No. 1539',
                    'hotel_tel': '+86 21-18001653800',
                },
                {
                    'hotel_id': 1325,
                    'hotel_city': 310,
                    'hotel_cn_name': '上海水印山房客栈(石笋街分店)',
                    'hotel_en_name': '',
                    'hotel_cn_address': '上海浦东新区石笋街与新场大街交叉口',
                    'hotel_en_address': '',
                    'hotel_tel': '+86 21-15902149748',
                },
                {
                    'hotel_id': 1327,
                    'hotel_city': 310,
                    'hotel_cn_name': '上海尚客舍公寓',
                    'hotel_en_name': '',
                    'hotel_cn_address': '上海浦东新区新场古镇新场大街198号',
                    'hotel_en_address': '',
                    'hotel_tel': '+86 21-15001903385',
                },
                {
                    'hotel_id': 1328,
                    'hotel_city': 310,
                    'hotel_cn_name': '上海小幸运民宿',
                    'hotel_en_name': '',
                    'hotel_cn_address': '上海浦东新区新场大街122号',
                    'hotel_en_address': '',
                    'hotel_tel': '+86 21-13611607273',
                },
                {
                    'hotel_id': 1329,
                    'hotel_city': 310,
                    'hotel_cn_name': '上海浓舍民宿',
                    'hotel_en_name': '',
                    'hotel_cn_address': '上海市浦东新区新场古镇新场大街457号',
                    'hotel_en_address': '',
                    'hotel_tel': '+86 21-18621502021',
                },
                {
                    'hotel_id': 1330,
                    'hotel_city': 310,
                    'hotel_cn_name': '花筑·上海裕大俚舍民宿',
                    'hotel_en_name': '',
                    'hotel_cn_address': '上海市浦东新区新场镇新场大街169号、171号、173号',
                    'hotel_en_address': '',
                    'hotel_tel': '+86 21-13621762539',
                },
                {
                    'hotel_id': 1331,
                    'hotel_city': 310,
                    'hotel_cn_name': '上海青花堂客栈',
                    'hotel_en_name': '',
                    'hotel_cn_address': '上海市浦东新区新场镇新场大街514号',
                    'hotel_en_address': '',
                    'hotel_tel': '+86 21-17821813245',
                },
                {
                    'hotel_id': 1333,
                    'hotel_city': 310,
                    'hotel_cn_name': '上海斑竹雅居民宿',
                    'hotel_en_name': '',
                    'hotel_cn_address': '上海市浦东新区康新公路东200米',
                    'hotel_en_address': '',
                    'hotel_tel': '+86 21-13601740343',
                },//**/
            ];
            break;
        case 'tiantan-pcbooking.eworldsoft.com':
            obj.hotel_id = 1361;//酒店ID
            obj.hotel_type = 1;//1单体酒店 2集团酒店
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://tiantan.eworldsoft.com/api';
            obj.web_url = 'https://www.tiantanhotel.com.cn/';
            obj.hotel_logo = 'https://www.tiantanhotel.com.cn/images/logo/logo2.png';
            obj.menu = [
                {
                    'name': '首页',
                    'en_name': 'Index',
                    'cn_url': 'https://www.tiantanhotel.com.cn/cn/index.html',
                    'en_url': 'https://www.tiantanhotel.com.cn/cn/index.html',
                },
                {
                    'name': '温馨客房',
                    'en_name': 'Room&Suite',
                    'cn_url': 'https://www.tiantanhotel.com.cn/cn/room.html',
                    'en_url': 'https://www.tiantanhotel.com.cn/cn/room.html',
                },
                {
                    'name': '餐饮美食',
                    'en_name': 'F&B',
                    'cn_url': 'https://www.tiantanhotel.com.cn/cn/dining.html',
                    'en_url': 'https://www.tiantanhotel.com.cn/cn/dining.html',
                },
                {
                    'name': '会议/宴会',
                    'en_name': 'Conference&Banquet',
                    'cn_url': 'https://www.tiantanhotel.com.cn/cn/meeting.html',
                    'en_url': 'https://www.tiantanhotel.com.cn/cn/meeting.html',
                },
            ]
            obj.city_list = [
                {
                    'code': 52,
                    'name': '北京',
                    'en_name': 'Beijing'
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 1361,
                    'hotel_city': 52,
                    'hotel_cn_name': '北京天坛饭店',
                    'hotel_en_name': 'TIANTAN HOTEL',
                    'hotel_cn_address': '北京市东城区体育馆路1-3号',
                    'hotel_en_address': '',
                    'hotel_tel': '+86 10 67190158',
                },
            ];
            break;
        case 'gwcsjt-pcbooking.eworldsoft.com':
            obj.hotel_id = 2;
            obj.hotel_type = 2;//2集团
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://dev-sxc.eworldsoft.com/api';
            obj.web_url = 'https://gwcsjt.eworldsoft.com';
            obj.hotel_logo = 'https://gwcsjt.eworldsoft.com/images/logo.png';
            obj.menu = [
                {
                    'name': '首页',
                    'en_name': 'HOME',
                    'cn_url': 'https://gwcsjt.eworldsoft.com/index.html',
                    'en_url': 'https://gwcsjt.eworldsoft.com/en/index.html',
                },
                {
                    'name': '酒店介绍',
                    'en_name': 'ABOUT',
                    'cn_url': 'https://gwcsjt.eworldsoft.com/cn/about.html',
                    'en_url': 'https://gwcsjt.eworldsoft.com/en/about.html',
                },
                {
                    'name': '温馨客房',
                    'en_name': 'ROOMS',
                    'cn_url': 'https://gwcsjt.eworldsoft.com/cn/room.html',
                    'en_url': 'https://gwcsjt.eworldsoft.com/en/room.html',
                },
                {
                    'name': '餐饮美食',
                    'en_name': 'DINING',
                    'cn_url': 'https://gwcsjt.eworldsoft.com/cn/dining.html',
                    'en_url': 'https://gwcsjt.eworldsoft.com/en/dining.html',
                },
                {
                    'name': '会议/宴会',
                    'en_name': 'MEETING',
                    'cn_url': 'https://gwcsjt.eworldsoft.com/cn/meeting.html',
                    'en_url': 'https://gwcsjt.eworldsoft.com/en/meeting.html',
                },
                {
                    'name': '新闻促销',
                    'en_name': 'NEWS',
                    'cn_url': 'https://gwcsjt.eworldsoft.com/cn/news.html',
                    'en_url': 'https://gwcsjt.eworldsoft.com/en/news.html',
                },
                {
                    'name': '休闲娱乐',
                    'en_name': 'lEISURE',
                    'cn_url': 'https://gwcsjt.eworldsoft.com/cn/leisure.html',
                    'en_url': 'https://gwcsjt.eworldsoft.com/en/leisure.html',
                },
                {
                    'name': '周边信息',
                    'en_name': 'TOURISM',
                    'cn_url': 'https://gwcsjt.eworldsoft.com/cn/traveling.html',
                    'en_url': 'https://gwcsjt.eworldsoft.com/en/traveling.html',
                },
            ]
            obj.city_list = [
                {
                    'code': 321,
                    'name': '上海',
                    'en_name': 'shang hai'
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 3,
                    'hotel_city': 321,
                    'hotel_cn_name': '上海悠庭皇家驿栈(测试)',
                    'hotel_en_name': 'Shanghai Youting Royal Posthouse',
                    'hotel_cn_address': '上海市浦东新区新场镇港西街6号(石笋街82号桥下)',
                    'hotel_en_address': 'Shanghai Pudong New Area, Xinchang Town, Gangxi Street No. 6',
                    'hotel_tel': '+86 21-68171370',
                },
                {
                    'hotel_id': 4,
                    'hotel_city': 321,
                    'hotel_cn_name': '上海璞素上居民宿(测试)',
                    'hotel_en_name': 'Shanghai Pu Su Shang Residential Accommodation',
                    'hotel_cn_address': '上海市浦东新区新场镇蒋桥村1539号',
                    'hotel_en_address': 'Shanghai Pudong New Area, Xinchang Town, Jiangqiao Village No. 1539',
                    'hotel_tel': '+86 21-18001653800',
                },
            ];
            break;
        case 'gwcsdt-pcbooking.eworldsoft.com':
            obj.hotel_id = 5;//酒店ID
            obj.hotel_type = 1;//1单体酒店 2集团酒店
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://dev-danti.eworldsoft.com/api';
            obj.web_url = 'https://gwcsdt.eworldsoft.com/';
            obj.hotel_logo = 'https://gwcsdt.eworldsoft.com/images/logo.png';
            obj.menu = [
                {
                    'name': '首页',
                    'en_name': 'HOME',
                    'cn_url': 'https://gwcsdt.eworldsoft.com/index.html',
                    'en_url': 'https://gwcsdt.eworldsoft.com/en/index.html',
                },
                {
                    'name': '酒店介绍',
                    'en_name': 'ABOUT',
                    'cn_url': 'https://gwcsdt.eworldsoft.com/cn/about.html',
                    'en_url': 'https://gwcsdt.eworldsoft.com/en/about.html',
                },
                {
                    'name': '温馨客房',
                    'en_name': 'ROOMS',
                    'cn_url': 'https://gwcsdt.eworldsoft.com/cn/room.html',
                    'en_url': 'https://gwcsdt.eworldsoft.com/en/room.html',
                },
                {
                    'name': '餐饮美食',
                    'en_name': 'DINING',
                    'cn_url': 'https://gwcsdt.eworldsoft.com/cn/dining.html',
                    'en_url': 'https://gwcsdt.eworldsoft.com/en/dining.html',
                },
                {
                    'name': '会议/宴会',
                    'en_name': 'MEETING',
                    'cn_url': 'https://gwcsdt.eworldsoft.com/cn/meeting.html',
                    'en_url': 'https://gwcsdt.eworldsoft.com/en/meeting.html',
                },
                {
                    'name': '新闻促销',
                    'en_name': 'NEWS',
                    'cn_url': 'https://gwcsdt.eworldsoft.com/cn/news.html',
                    'en_url': 'https://gwcsdt.eworldsoft.com/en/news.html',
                },
                {
                    'name': '休闲娱乐',
                    'en_name': 'lEISURE',
                    'cn_url': 'https://gwcsdt.eworldsoft.com/cn/leisure.html',
                    'en_url': 'https://gwcsdt.eworldsoft.com/en/leisure.html',
                },
                {
                    'name': '周边信息',
                    'en_name': 'TOURISM',
                    'cn_url': 'https://gwcsdt.eworldsoft.com/cn/traveling.html',
                    'en_url': 'https://gwcsdt.eworldsoft.com/en/traveling.html',
                },
            ]
            obj.city_list = [
                {
                    'code': 321,
                    'name': '上海',
                    'en_name': 'shang hai'
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 5,
                    'hotel_city': 321,
                    'hotel_cn_name': '单体酒店测试版',
                    'hotel_en_name': 'Shanghai Single Hotel Beta Version',
                    'hotel_cn_address': '上海市浦东新区新场镇港西街6号(石笋街82号桥下)',
                    'hotel_en_address': 'Shanghai Pudong New Area, Xinchang Town, Gangxi Street No. 6',
                    'hotel_tel': '+86 21-68171370',
                },
            ];
            break;
        case 'szjingdu-pcbooking.eworldsoft.com':
            obj.hotel_id = 1370;
            obj.hotel_type = 1;//1单体酒店
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://szjingdu.eworldsoft.com/api';
            obj.web_url = 'http://www.szjingdu.com/zh_CN/';
            obj.hotel_logo = '';
            obj.menu = [
                {
                    'name': '首页',
                    'en_name': 'Home',
                    'cn_url': 'http://www.szjingdu.com/zh_CN/#headerWidget18256',
                    'en_url': 'http://www.szjingdu.com/en_US/#headerWidget19315'
                },
                {
                    'name': '酒店介绍',
                    'en_name': 'About Us',
                    'cn_url': 'http://www.szjingdu.com/zh_CN/#layoutWidget18283',
                    'en_url': 'http://www.szjingdu.com/en_US/#layoutWidget19342',
                },
                {
                    'name': '客房介绍',
                    'en_name': 'Room',
                    'cn_url': 'http://www.szjingdu.com/zh_CN/#layoutWidget18275',
                    'en_url': 'http://www.szjingdu.com/en_US/#layoutWidget19334',
                },
                {
                    'name': '餐饮美食',
                    'en_name': 'Food',
                    'cn_url': 'http://www.szjingdu.com/zh_CN/#layoutWidget18259',
                    'en_url': 'http://www.szjingdu.com/en_US/#layoutWidget19318',
                },
                {
                    'name': '会议宴会',
                    'en_name': 'Meeting',
                    'cn_url': 'http://www.szjingdu.com/zh_CN/#layoutWidget18262',
                    'en_url': 'http://www.szjingdu.com/en_US/#layoutWidget19321',
                },
                {
                    'name': '康体娱乐',
                    'en_name': 'Entertainment',
                    'cn_url': 'http://www.szjingdu.com/zh_CN/#layoutWidget18264',
                    'en_url': 'http://www.szjingdu.com/en_US/#layoutWidget19323',
                },
                {
                    'name': '酒店新闻',
                    'en_name': 'News',
                    'cn_url': 'http://www.szjingdu.com/zh_CN/#listWidget18274',
                    'en_url': 'http://www.szjingdu.com/zh_CN/#listWidget18274',
                },
                {
                    'name': '酒店相册',
                    'en_name': 'Hotel Gallery',
                    'cn_url': 'http://www.szjingdu.com/zh_CN/#galleryWidget18269',
                    'en_url': 'http://www.szjingdu.com/en_US/#galleryWidget19328',
                },
                {
                    'name': '联系我们',
                    'en_name': 'Contact Us',
                    'cn_url': 'http://www.szjingdu.com/zh_CN/#locationWidget18278',
                    'en_url': 'http://www.szjingdu.com/en_US/#locationWidget19337',
                },
            ]
            obj.city_list = [
                {
                    'code': 77,
                    'name': '深圳',
                    'en_name': 'Shen Zhen'
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 1370,
                    'hotel_city': 77,
                    'hotel_cn_name': '京基晶都酒店',
                    'hotel_en_name': 'KINGKEY ORIENTAL REGENT HOTEI',
                    'hotel_cn_address': '广东省深圳市罗湖区深南中路与红岭南路交汇处',
                    'hotel_en_address': 'Intersection of Shennan Middle Road and Hongling South Road, Luohu District, Shenzhen City, Guangdong Province',
                    'hotel_tel': '+86 755 82247000',
                }
            ];
            break;
        default:
            obj.hotel_id = 5;//酒店ID
            obj.hotel_type = 1;//1单体酒店 2集团酒店
            obj.pms_protocol = false;//默认自有系统协议公司，true为PMS协议公司
            obj.api_url = 'https://dev-danti.eworldsoft.com/api';
            obj.web_url = 'https://gwcsdt.eworldsoft.com/';
            obj.hotel_logo = 'https://gwcsdt.eworldsoft.com/images/logo.png';
            obj.menu = [
                {
                    'name': '首页',
                    'en_name': 'HOME',
                    'cn_url': 'https://gwcsdt.eworldsoft.com/index.html',
                    'en_url': 'https://gwcsdt.eworldsoft.com/en/index.html',
                },
                {
                    'name': '酒店介绍',
                    'en_name': 'ABOUT',
                    'cn_url': 'https://gwcsdt.eworldsoft.com/cn/about.html',
                    'en_url': 'https://gwcsdt.eworldsoft.com/en/about.html',
                },
                {
                    'name': '温馨客房',
                    'en_name': 'ROOMS',
                    'cn_url': 'https://gwcsdt.eworldsoft.com/cn/room.html',
                    'en_url': 'https://gwcsdt.eworldsoft.com/en/room.html',
                },
                {
                    'name': '餐饮美食',
                    'en_name': 'DINING',
                    'cn_url': 'https://gwcsdt.eworldsoft.com/cn/dining.html',
                    'en_url': 'https://gwcsdt.eworldsoft.com/en/dining.html',
                },
                {
                    'name': '会议/宴会',
                    'en_name': 'MEETING',
                    'cn_url': 'https://gwcsdt.eworldsoft.com/cn/meeting.html',
                    'en_url': 'https://gwcsdt.eworldsoft.com/en/meeting.html',
                },
                {
                    'name': '新闻促销',
                    'en_name': 'NEWS',
                    'cn_url': 'https://gwcsdt.eworldsoft.com/cn/news.html',
                    'en_url': 'https://gwcsdt.eworldsoft.com/en/news.html',
                },
                {
                    'name': '休闲娱乐',
                    'en_name': 'lEISURE',
                    'cn_url': 'https://gwcsdt.eworldsoft.com/cn/leisure.html',
                    'en_url': 'https://gwcsdt.eworldsoft.com/en/leisure.html',
                },
                {
                    'name': '周边信息',
                    'en_name': 'TOURISM',
                    'cn_url': 'https://gwcsdt.eworldsoft.com/cn/traveling.html',
                    'en_url': 'https://gwcsdt.eworldsoft.com/en/traveling.html',
                },
            ]
            obj.city_list = [
                {
                    'code': 321,
                    'name': '上海',
                    'en_name': 'shang hai'
                }
            ]
            obj.hotel_list = [
                {
                    'hotel_id': 5,
                    'hotel_city': 321,
                    'hotel_cn_name': '单体酒店测试版',
                    'hotel_en_name': 'Shanghai Single Hotel Beta Version',
                    'hotel_cn_address': '上海市浦东新区新场镇港西街6号(石笋街82号桥下)',
                    'hotel_en_address': 'Shanghai Pudong New Area, Xinchang Town, Gangxi Street No. 6',
                    'hotel_tel': '+86 21-68171370',
                },
            ];
            obj.memberCfg = {
                "C": {
                    "cardNameZh": "银卡会员",
                    "cardUpTextZh": "注册即成为银卡会员",
                    "equity": [
                        {
                            "icon": "courtesy",
                            "title": "欢迎水果",
                            "gray": false,
                        },
                        {
                            "icon": "discounts",
                            "title": "95折",
                            "gray": false,
                        },
                        {
                            "icon": "delay",
                            "title": "延时退房\n至14:00",
                            "gray": false,
                        },
                        {
                            "icon": "point",
                            "title": "1倍积分奖励",
                            "gray": false,
                        },
                        {
                            "icon": "laundry",
                            "title": "自助洗衣",
                            "gray": true,
                        },
                        {
                            "icon": "priority",
                            "title": "专属通道",
                            "gray": true,
                        },
                        {
                            "icon": "convert-point",
                            "title": "积分兑换",
                            "gray": false,
                        },
                        {
                            "icon": "birthday",
                            "title": "生日礼",
                            "gray": true,
                        },
                        {
                            "icon": "meeting-room",
                            "title": "会议室",
                            "gray": true,
                        },
                        {
                            "icon": "cot",
                            "title": "免费加床",
                            "gray": true,
                        }
                    ],
                    "equityPlus": [
                        {
                            "icon": "plus1",
                            "title": "会员楼层",
                            "gray": false,
                        },
                        {
                            "icon": "plus2",
                            "title": "冷暖相知",
                            "gray": false,
                        },
                        {
                            "icon": "plus11",
                            "title": "无微不至",
                            "gray": false,
                        },
                        {
                            "icon": "plus4",
                            "title": "一触即发",
                            "gray": true,
                        },
                        {
                            "icon": "plus9",
                            "title": "静夜无声",
                            "gray": true,
                        },
                        {
                            "icon": "plus12",
                            "title": "养足精神",
                            "gray": true,
                        },
                        {
                            "icon": "plus5",
                            "title": "西装革履",
                            "gray": true,
                        },
                        {
                            "icon": "plus7",
                            "title": "一室芬芳",
                            "gray": true,
                        },
                        {
                            "icon": "plus13",
                            "title": "茶香四溢",
                            "gray": true,
                        },
                        {
                            "icon": "plus15",
                            "title": "畅通无阻",
                            "gray": true,
                        },
                        {
                            "icon": "plus10",
                            "title": "享浴时刻",
                            "gray": true,
                        },
                        {
                            "icon": "plus14",
                            "title": "味浓香醇",
                            "gray": true,
                        }
                    ]
                },
                "D": {
                    "cardNameZh": "金卡会员",
                    "cardUpTextZh": "达到1000积分，有效期自升至金卡会员一年",
                    "equity": [
                        {
                            "icon": "courtesy",
                            "title": "VC水果",
                            "gray": false,
                        },
                        {
                            "icon": "discounts",
                            "title": "会员90折",
                            "gray": false,
                        },
                        {
                            "icon": "delay",
                            "title": "延时退房\n至14:00",
                            "gray": false,
                        },
                        {
                            "icon": "point",
                            "title": "1倍\n积分奖励",
                            "gray": false,
                        },
                        {
                            "icon": "laundry",
                            "title": "自助洗衣",
                            "gray": true,
                        },
                        {
                            "icon": "priority",
                            "title": "专属通道",
                            "gray": true,
                        },
                        {
                            "icon": "convert-point",
                            "title": "积分兑换",
                            "gray": false,
                        },
                        {
                            "icon": "birthday",
                            "title": "200积分",
                            "gray": true,
                        },
                        {
                            "icon": "meeting-room",
                            "title": "会议室",
                            "gray": true,
                        },
                        {
                            "icon": "cot",
                            "title": "免费加床",
                            "gray": true,
                        }
                    ],
                    "equityPlus": [
                        {
                            "icon": "plus1",
                            "title": "会员楼层",
                            "gray": false,
                        },
                        {
                            "icon": "plus2",
                            "title": "冷暖相知",
                            "gray": false,
                        },
                        {
                            "icon": "plus11",
                            "title": "无微不至",
                            "gray": false,
                        },
                        {
                            "icon": "plus4",
                            "title": "一触即发",
                            "gray": false,
                        },
                        {
                            "icon": "plus9",
                            "title": "静夜无声",
                            "gray": false,
                        },
                        {
                            "icon": "plus12",
                            "title": "养足精神",
                            "gray": true,
                        },
                        {
                            "icon": "plus5",
                            "title": "西装革履",
                            "gray": true,
                        },
                        {
                            "icon": "plus7",
                            "title": "一室芬芳",
                            "gray": true,
                        },
                        {
                            "icon": "plus13",
                            "title": "茶香四溢",
                            "gray": true,
                        },
                        {
                            "icon": "plus15",
                            "title": "畅通无阻",
                            "gray": true,
                        },
                        {
                            "icon": "plus10",
                            "title": "享浴时刻",
                            "gray": true,
                        },
                        {
                            "icon": "plus14",
                            "title": "味浓香醇",
                        }
                    ]
                },
                "E": {
                    "cardNameZh": "铂金卡",
                    "cardUpTextZh": "达到1500积分，有效期自升至铂金卡一年",
                    "equity": [
                        {
                            "icon": "courtesy",
                            "title": "VC+水果",
                            "gray": false,
                        },
                        {
                            "icon": "discounts",
                            "title": "会员85折",
                            "gray": false,
                        },
                        {
                            "icon": "delay",
                            "title": "延时退房\n至15:00",
                            "gray": false,
                        },
                        {
                            "icon": "point",
                            "title": "1倍\n积分奖励",
                            "gray": false,
                        },
                        {
                            "icon": "laundry",
                            "title": "酒店代洗",
                            "gray": true,
                        },
                        {
                            "icon": "priority",
                            "title": "专属通道",
                            "gray": true,
                        },
                        {
                            "icon": "convert-point",
                            "title": "积分兑换",
                            "gray": false,
                        },
                        {
                            "icon": "birthday",
                            "title": "500积分",
                            "gray": true,
                        },
                        {
                            "icon": "meeting-room",
                            "title": "会议室\n免费1小时",
                            "gray": true,
                        },
                        {
                            "icon": "cot",
                            "title": "免费加床",
                            "gray": true,
                        }
                    ],
                    "equityPlus": [
                        {
                            "icon": "plus1",
                            "title": "会员楼层",
                            "gray": false,
                        },
                        {
                            "icon": "plus2",
                            "title": "冷暖相知",
                            "gray": false,
                        },
                        {
                            "icon": "plus11",
                            "title": "无微不至",
                            "gray": false,
                        },
                        {
                            "icon": "plus4",
                            "title": "一触即发",
                            "gray": false,
                        },
                        {
                            "icon": "plus9",
                            "title": "静夜无声",
                            "gray": false,
                        },
                        {
                            "icon": "plus12",
                            "title": "养足精神",
                            "gray": false,
                        },
                        {
                            "icon": "plus5",
                            "title": "西装革履",
                            "gray": false,
                        },
                        {
                            "icon": "plus7",
                            "title": "一室芬芳",
                            "gray": false,
                        },
                        {
                            "icon": "plus13",
                            "title": "茶香四溢",
                            "gray": false,
                        },
                        {
                            "icon": "plus15",
                            "title": "畅通无阻",
                            "gray": false,
                        },
                        {
                            "icon": "plus10-disable",
                            "title": "享浴时刻",
                            "gray": false,
                        },
                        {
                            "icon": "plus14-disable",
                            "title": "味浓香醇",
                            "gray": false,
                        }
                    ]
                },
            }
            break;
    }

    //以下是所有酒店公共数据
    obj.window = [
        {
            "key": 1,
            "value": "无窗户",
            "en_value": "windowless room "
        },
        {
            "key": 2,
            "value": "有窗户",
            "en_value": "rooms with window"
        },
        {
            "key": 3,
            "value": "部分有窗",
            "en_value": "some with windows"
        }
    ]
    obj.breakfast = [
        {
            "key": 0,
            "value": "无早",
            "en_value": "none"
        },
        {
            "key": 1,
            "value": "单早",
            "en_value": "1 BF"
        },
        {
            "key": 2,
            "value": "双早",
            "en_value": "2 BF"
        },
        {
            "key": 3,
            "value": "多早",
            "en_value": "3 BF"
        },
        {
            "key": 4,
            "value": "含早",
            "en_value": "include BF"
        }
    ]
    obj.room_order_status = [
        {
            "key": 0,
            "value": '全部',
            "en_value": 'All'
        },
        {
            "key": 1,
            "value": '待付款',
            "en_value": 'Waiting for payment'
        },
        {
            "key": 6,
            "value": '待确认',
            "en_value": 'Pending confirmed'
        },
        {
            "key": 2,
            "value": '待入住',
            "en_value": 'Pending check-in'
        },
        {
            "key": 3,
            "value": '已入住',
            "en_value": 'Checked in'
        },
        {
            "key": 5,
            "value": '已离店',
            "en_value": 'Checked out'
        },
        {
            "key": 4,
            "value": '已取消',
            "en_value": 'Cancelled'
        },
        {
            "key": 7,
            "value": '修改',
            "en_value": 'Modify'
        },
    ]
    obj.coupon_status = [
        {
            "key": 1,
            "value": '待使用',
            "en_value": 'Waiting for using'
        },
        {
            "key": 2,
            "value": '已使用',
            "en_value": 'Used'
        },
        {
            "key": 3,
            "value": '已失效',
            "en_value": 'Expired'
        }
    ]

    return obj;
}