import axios from '@/utils/request';

const room = {
    getRoomList(params) {
        return axios.post('/v1/room.list.post', params);
    },
    //会员登录下单
    loginAddOrder(params) {
        return axios.post('/v1/room.addOrder.post', params);
    },
    //散客下单
    noLoginAddOrder(params) {
        return axios.post('/v1/room.FITAddOrder.post', params);
    },
    //微信扫码支付
    wechatNativePayOrder(params) {
        return axios.post('/v1/pay.orderNativePay.post', params);
    },
    //支付宝扫码支付
    aliPayNativePayOrder(params) {
        return axios.post('/v1/pay.orderPayForAlipay.post', params);
    },
    //检测支付状态
    nativePayStatus(params) {
        return axios.post('/v1/pay.orderPaymentStatus.post', params);
    },
    //查询床型
    bedList(params) {
        return axios.get('/v1/room.bedTypeList.get', {params: params});
    },
    //散客订单查询
    searchOrder(params) {
        return axios.post('/v1/room.FITSearchOrder.post', params);
    },
    //散客取消订单
    roomOrderCancel(params) {
        return axios.post('/v1/room.FITCancelOrder.post', params);
    },
    //微信订单取消并退款
    roomOrderWeChatCancelRefund(params) {
        return axios.post('/v1/refund.orderPayForMiniProgram.post', params);
    },
    //支付宝订单取消并退款
    roomOrderAliPayCancelRefund(params) {
        return axios.post('/v1/refund.orderPayForAlipay.post', params);
    },
}

export default room;