module.exports = {
    language: {
        name: 'English'
    },
    user: {
        loginTxt: '登录',
        registerTxt: '注册',
        registerNow: '立即注册',
        loginSuccess: '登录成功',
        registerSuccess: '注册成功',
        promise: '官方渠道预订享低价保证',
        phone: '请输入手机号码',
        validPhone: '请输入有效手机号码',
        code: '请输入验证码',
        sending: '发送中',
        validCode: '输入短信验证码格式错误',
        emailTxt: '请输入电子邮箱',
        validEmail: '请输入有效邮箱地址',
        loginPassword: '请输入登录密码',
        notLogin: '不登录，直接预订',
        userCenter: '用户中心',
        welcomeRegister: '欢迎注册',
        openingHotelJourney: '开启我们的酒店之旅',
        morningTip: '上午好！',
        noonTip: '下午好！',
        nightTip: '晚上好！',
        officialMember: '官网会员',
        protocolCompany: '协议公司',
        orderInquiry: '订单查询',
        firstName: '名字',
        firstNameTxt: '请输入名字',
        lastName: '姓氏',
        lastNameTxt: '请输入姓氏',
        gender: '性别',
        male: '先生',
        female: '女士',
        contactInformation: '联系人信息',
        mobile: '手机号码',
        tel: '固定电话',
        telTxt: '请输入固定电话',
        email: '电子邮箱',
        birthday: '生日',
        selectBirthday: '选择生日',
        roomRequires: '房间要求',
        roomRequiresTxt: '请输入房间要求',
        orderConfirmEmail: '这是我们将向您发送订单确认信息的电子邮件地址',
        expectedArrival: '预计到店',
        specialRequirements	: '特殊要求',
        arrivedTime: '到达时间',
        selectTime: '选择时间',
        remarks: '备注',
        remarksTxt: '请输入备注',
        guestName: '客人姓名',
        invoiceTitle: '发票抬头',
        passwordTxt: '请输入密码',
        loginNameTxt: '请输入登录账号',
        invoiceContactNameTxt: '请输入收件人',
        sendAddressTxt: '请输入快递地址',
        editInfoSuccTxt: '用户资料更新成功',
        userInformation: '用户信息',
        oldPass: '原密码',
        oldPassTxt: '请输入原密码',
        newPass: '新密码',
        newPassTxt: '请输入新密码',
        validPassTxt: '输入密码格式不正确',
        matchPassTxt: '两次输入密码不一致',
        confirmPass: '确认密码',
        confirmPassTxt: '请再次输入新密码',
        inputPassTxt: '密码8~18位,包含数字,大小写字母,特殊字符',
        respected: '尊敬的',
        member: '会员',
        membershipCard: '会员卡',
        joinMember: '加入官网会员',
        joinMemberTxt: '尊享会员专属权益、会员专属价格及精彩礼遇',
        upgradingPoints: '升级所需积分',
        currentLevel: '当前等级权益',
        moreLevel: '查看更多等级权益',
        moreBenefits: '优+服务',
        memberBenefits: '会员权益',
        securityInfo: '请完善您的个人信息，以便获得更好的服务，妥善保管您的登录密码，以保障您的账户安全',
        rule: '使用规则',
        exchangeGift: '兑换好礼',
        pointsBalance: '积分余额',
        divide: '分',
        myPoints: '我的积分',
        dateQuery: '日期查询',
        search: '查询',
        pointsChange: '积分变化',
        description: '说明',
        createdTime: '创建时间',
        ruleDesc: '使用规则说明',
        useHotel: '适用酒店',
        couponName: '券名称',
        useScene: '适用场景',
        useRule: '使用规则',
        startEndTime: '有效期',
        LongTermEffective: '长期有效',
    },
    search: {
        checkIn: '入住',
        checkOut: '离店',
        evening: '晚',
        citySelect: '城市选择',
        hotelSelect: '酒店选择',
        roomGuest: '客房及宾客',
        roomCount: '客房',
        roomNumber: '客房数量',
        peoples: '成人',
        kids: '儿童',
        confirm: '确定',
        reserveCode: '促销编号',
        inputReserveCode: '促销编号',
        protocolCode: '协议公司编号',
        inputProtocolCode: '协议公司编号',
        resetSearch: '重新搜索',
        specialRoomPrice: '特殊代码或房价',
        to: '至',
        filterItems: '筛选项',
        breakfast: '早餐',
        bedType: '床型',
        window: '窗户',
    },
    home: {
        userCenter: '用户中心',
        personalCenter: '个人中心',
        memberHome: '会员首页',
        booking: '客房预订',
        points: '我的积分',
        pointsBalance: '积分余额',
        cardVoucher: '我的卡券',
        couponVoucher: '优惠券',
        giftVoucher: '礼包券',
        freeVoucher: '免费券',
        myOrder: '我的订单',
        roomOrders: '客房订单',
        mallOrders: '实物商城订单',
        electronicOrders: '电子券商城订单',
        pointsMallOrders: '积分商城订单',
        seckillOrders: '秒杀订单',
        foodOrders: '餐饮美食订单',
        meetingOrders: '共享会议室订单',
        activityOrders: '活动订单',
        apartmentOrders: '服务公寓订单',
        equityCardOrders: '权益卡订单',
        commonInformation: '常用信息',
        shippingAddress: '配送地址',
        invoiceInformation: '发票信息',
        accountSettings: '账户设置',
        basicData: '基础资料',
        changePassword: '修改密码',
        logout: '退出登录',
        loggedOut: '已退出登录',
        noData: '无数据',
    },
    room: {
        address: '酒店地址',
        telephone: '酒店电话',
        openTime: '开业时间',
        roomNumber: '房间数量',
        moreFacilities: '显示更多酒店设施',
        close: '关闭',
        minPrice: '最低房价',
        start: '起',
        bookingNow: '立即预订',
        nowNoRoom: '选定的日期内无空房',
        roomDetails: '客房详情',
        roomPriceDetails: '房价详情',
        room: '室',
        hall: '厅',
        people: '人',
        floor: '层',
        cancelable: '可取消',
        nonCancelable: '不可取消',
        onlinePayment: '在线支付',
        cashPayment: '前台现付',
        purePoints: '纯积分',
        points: '积分',
        cashPoints: '积分+现金',
        noData: '未查询到客房信息',
        fillBookingInfo: '填写预订信息',
        paymentMethod: '支付方式',
        roomConfirm: '客房确认',
        bookingInformation: '预订信息',
        bookingRoomNumber: '预订间数',
        guest: '入住人',
        occupancy: '入住人数',
        bookingDate: '预订日期',
        checkInDays: '入住天数',
        chooseAddedServices: '选择其他服务',
        addedServicesName: '服务名称',
        addedServicesContent: '服务内容',
        addedServicesPrice: '单价',
        addedServicesQuantity: '数量',
        free: '免费',
        guestInformation: '宾客信息',
        checkInInformation: '入住人信息',
        total: '总计',
        back: '上一步',
        next: '下一步',
        expenseDetails: '消费明细',
        roomRate: '房价信息',
        date: '日期',
        bf: '早餐',
        night: '间夜',
        room_number: '间',
        amount: '金额',
        subtotal: '小计',
        serviceFees: '服务费',
        otherServices: '其他服务',
        discountDetails: '优惠明细',
        discountName: '优惠名称',
        discountAmount: '优惠金额',
        priceDescription: '价格说明',
        reservetionPolicy: '预订政策',
        cancelltionPolicy: '取消政策',
        couponEmptyData: '无数据',
        guestCheckInInformation: '客人入住信息',
        bookingConditions: '我年满18岁，我遵守本网站的条款和条件及已阅读右侧预订条件',
        confirmSubmit: '确认提交',
        submitSuccessPay: '订单已提交成功',
        checkBookingNotice: '请勾选预定条款须知',
        submitSuccess: '订单提交成功',
        waitingPayTime: '待支付时间',
        orderNo: '订单号',
        orderNoTxt: '请输入订单号',
        wechatScan: '请使用微信扫一扫',
        scanQRPay: '扫描二维码支付',
        effectiveEmail: '这是我们将向您发送订单确认信息的电子邮件地址',
        couponPackSelect: '券包选择',
        pleaseSelect: '请选择',
        couponVoucher: '优惠券',
        giftVoucher: '礼包券',
        checkInTime: '入住时间',
        createTime: '下单时间',
        orderDetails: '订单详情',
        editOrder: '修改订单',
        payNow: '立即支付',
        cancelOrder: '取消订单',
        totalPage: '共',
        page: '页',
        records: '条数据',
        searchKeyword: '请输入姓名、手机号、订单号',
        searchDate: '日期查询',
        startDate: '开始日期',
        endDate: '截止日期',
        query: '查询',
        cancelOrderSuccess: '订单取消成功',
        orderStatus: '订单状态',
        toPay: '去支付',
        surplus: '剩余',
        nowPaymentTxt: '订单已成功提交，请尽快完成付款',
        invoiceTitle: '发票抬头',
        invoiceType: '发票类型',
        wayGetInvoice: '取票方式',
        getDate: '领取日期',
        getDateTxt: '请选择领取日期',
        getTime: '领取时间',
        getTimeTxt: '请选择领取时间',
        receviePerson: '收件人',
        receviePersonTxt: '请填写收件人',
        deliverAddr: '快递地址',
        deliverAddrTxt: '请填写快递地址',
        addInvoiceInformation: '新增发票信息',
        ordinaryInvoice: '增值税普通发票',
        specialInvoice: '增值税专用发票',
        personal: '个人',
        company: '公司',
        companyName: '单位名称',
        companyNameTxt: '请填写单位名称',
        taxNum: '单位税号',
        taxNumTxt: '请填写单位税号',
        registerAddr: '注册地址',
        registerAddrTxt: '请填写注册地址',
        registerPhone: '注册电话',
        registerPhoneTxt: '请填写注册电话',
        bankName: '开户银行',
        bankNameTxt: '请填写开户银行',
        bankCount: '银行账户',
        bankCountTxt: '请填写银行账户',
        connectPhone: '联系人手机',
        connectPhoneTxt: '请填写联系人手机',
        name: '姓名',
        nameTxt: '请输入姓名',
        save: '保存',
        hotelDescription: '酒店说明',
        paymentInstructions: '支付说明',
        paymentInstructionsTxt: '预订单请于30分钟内完成付款',
        exceedsTxt: '超时订单',
        exceedsCancelTxt: '将会自动取消订单',
        pendingExceedsCancelTxt: '未超时支付的订单在【 个人中心-待支付】订单中进行重新支付',
        success: '支付成功',
        estimatedRoomRate: '预计房费',
        week: '周',
        basicInformation: '基础信息',
        roomTypeFacilities: '房型设施',
        roomPriceLow: '房价低至',
    }


}