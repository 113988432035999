import axios from "axios"
import store from '../store/index';
import router from "@/router";
import {Message, Loading} from 'element-ui';
import {getHotelConfig} from "@/utils/config";

// 创建axios实例
const instance = axios.create({
    timeout: 50000, responseType: "json", withCredentials: false, headers: {
        "Content-Type": "application/json;charset=UTF-8"
    }
});

let loadingInstance;
const startLoading = () => {
    loadingInstance = Loading.service({
        lock: true, //lock的修改符--默认是false
        text: "加载中...", //显示在加载图标下方的加载文案
        spinner: "el-icon-loading", //自定义加载图标类名
        //background: "rgba(0, 0, 0, 0.2)", //遮罩层颜色
    })
}

const endLoading = () => {
    loadingInstance.close();
}

/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = msg => {
    Message({
        message: msg, type: 'error', duration: 2000
    });
}

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
    router.replace({path: '/room/price-list'})
}

const _this = this;

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(config => {
    const cfg = getHotelConfig();
    config.baseURL = cfg.api_url;
    //startLoading();
    const token = localStorage.getItem('token');
    if (token) config.headers.Authorization = token;

    const hotelId = cfg.hotel_id;
    if (hotelId) {
        config.headers.hotelId = hotelId;
    } else {
        config.headers.hotelId = 10000;
    }
    config.headers.lang = localStorage.getItem('lang');
    return config;
}, error => Promise.error(error));

// 响应拦截器
instance.interceptors.response.use(res => {
    //endLoading();
    const code = res.data.code;
    if (code === 1013 || code === 1022) {
        tip(res.data.message)
        return Promise.resolve(res.data);
    }
    if (code === 401) {
        tip(res.data.message);
        localStorage.removeItem('hotelId')
        localStorage.removeItem('token')
        localStorage.removeItem('loginType')
        localStorage.removeItem('priceDetail')
        localStorage.removeItem('orderInfo')

        router.replace({
            path: '/login'
        })
    }
    if (code !== 200) {
        tip(res.data.message);
        return Promise.resolve(res.data);
    }
    return Promise.resolve(res.data)
}, error => {
    const {response} = error;
    if (response) {
        if (response.status === 500) {
            tip('服务异常')
            return Promise.resolve(response.data);
        }
        if (response.status === 422) {
            tip('参数错误')
            return Promise.resolve(response.data);
        }
        return Promise.reject(response);
    } else {
        // 处理断网的情况
        // eg:请求超时或断网时，更新state的network状态
        // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
        // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
        if (!window.navigator.onLine) {
            store.commit('changeNetwork', false);
        } else {
            return Promise.reject(response);
        }
    }
});

export default instance;